import {HttpModel} from './http.model';

export class Auth extends HttpModel {
  username: string;
  password: string;

  public deserialize(input: any): this {
    if (input) {
      Object.assign(this, input);
    }
    return this;
  }

  setUsername(value: string): Auth {
    this.username = value;
    return this;
  }

  setPassword(value: string): Auth {
    this.password = value;
    return this;
  }
}
