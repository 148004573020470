import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialComponentsModule} from './material-components/material-components.module';
import {SharedModule} from './shared/shared.module';
import { IntroComponent } from './components/intro/intro.component';
import {CdkColumnDef} from '@angular/cdk/table';
import { LoginComponent } from './components/login/login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {httpInterceptorProviders} from './services/http-interceptors';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {ChartsModule} from 'ng2-charts';
import { CopyrightComponent } from './components/copyright/copyright.component';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getCzechPaginatorIntl} from './internationalization/czech-paginator-intl';
import { DashboardHeatComponent } from './modules/dashboard/dashboard-heat/dashboard-heat.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    LoginComponent,
    LoadingIndicatorComponent,
    CopyrightComponent,
    DashboardHeatComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialComponentsModule,
    GoogleMapsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatMomentDateModule,
    ChartsModule,
  ],
  providers: [
    CdkColumnDef,
    httpInterceptorProviders,
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: 'cs'},
    {provide: MatPaginatorIntl, useValue: getCzechPaginatorIntl() },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
