export enum DevicePermissions {
  READ,
  MODIFY
}

export class AppConfig {
  public static LOGIN_TOKEN = 'login';
  public static TOKEN = 'jwtToken';
  public static DEFAULT_PAGE_SIZES = [5, 10, 25, 50, 100];
  public static DEFAULT_PAGE_SIZE = 25;
  public static USER_ROLES = [
    'ADMIN',
    'HW',
    'USER'
  ];
  public static METER_PERMISSIONS = [
    'READ',
    'MODIFY'
  ];
  // DATA
  // how many data points there are in aggregated data for 1 hous
  // 4 means - each 15 minutes
  public static SUM_DATA_PTS_IN_HOUR = 4;
  // how many minutes between data points of aggregated data - should correspond with SUM_DATA_PTS_IN_HOURS
  public static MINS_BETWEEN_SUM_DATAPOINTS = 15;
  // export formats for data tables
  public static DATA_EXPORT_FORMATS = [
    'xls',
    'xlsx',
    'csv',
    'txt',
    'json'
  ];
  // CHARTS
  public static LIVE_CHART_MAX_POINTS = 30;
  public static LIVE_POWER_CHART_UPDATE_MS = 5000;
  // DATETIMES
  public static DEFAULT_TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  // MAPS
  public static CZ_CENTER_COORDS: google.maps.LatLngLiteral = {
    lat: 49.8037633,
    lng: 15.4749126
  };
}
