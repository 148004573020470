import {MatTreeNestedDataSource} from '@angular/material/tree';
import {BehaviorSubject, Observable} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';

export class AbstractTreeDataSource<T> implements MatTreeNestedDataSource<T> {
  _data = new BehaviorSubject<T[]>(null);
  protected loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public length = 0;

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this._data.asObservable();
  }

  disconnect(): void {
    this._data.complete();
    this.loadingSubject.complete();
  }

  get data(): T[] {
    return this._data.value;
  }

  set data(value: T[]) {
    this._data.next(value);
  }

  isEmpty(): boolean {
    return this.length === 0;
  }

  protected setData(items: T[]): void {
    if (items) {
      this._data.next(items);
      this.length = items.length;
    }
  }

  /**
   * Returns current set of data of type T
   */
  public getData(): T[] {
    return this._data.value;
  }
}
