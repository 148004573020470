<div class="row">
  <div class="col-md-6 col-lg-8 pb-3 pb-lg-0 pr-md-0">
    <mat-card>
      <h2>Aktuální stav</h2>
      <p><strong>Modul zúčtování tepla není aktivní.</strong></p>
    </mat-card>
  </div>
  <div class="col-md-6 col-lg-4">
    <mat-card>
      <h2>Výpadky</h2>
    </mat-card>
  </div>
</div>
