import { Component, OnInit } from '@angular/core';
import {AppPageInterface, PageTitle} from '../../interfaces/app-page.interface';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements AppPageInterface, OnInit {
  pageTitle: PageTitle = {
    icon: 'login',
    name: 'Intro'
  };

  constructor() { }

  ngOnInit(): void {
  }



}
