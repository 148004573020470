<h2 mat-dialog-title>{{ this.title }}</h2>
<div mat-dialog-content class="d-flex flex-column">
  <mat-accordion class="my-2">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="edit-form">
      <mat-expansion-panel hideToggle expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>Základní údaje</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field appearance="outline" class="d-flex">
          <mat-label>Název</mat-label>
          <input matInput type="text" formControlName="title" required>
          <mat-error *ngIf="this.formControls['title'].invalid && this.formControls['title'].errors.required">
            Vyplňte prosím název měřáku
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="d-flex">
          <mat-label>Uzel</mat-label>
          <mat-select [formControl]="formControls['deviceId']" required>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let device of devices" [value]="device.id">
              {{ device.description }} (id {{ device.id }})
            </mat-option>
          </mat-select>
          <mat-hint>Uzel, do kterého je měřák zapojen</mat-hint>
          <mat-error *ngIf="this.formControls['deviceId'].invalid && this.formControls['deviceId'].errors.required">
            Vyberte prosím uzel, do kterého je měřák zapojen
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="d-flex">
          <mat-label>Index v rámci uzlu</mat-label>
          <input matInput type="number" formControlName="index" required>
          <mat-error *ngIf="this.formControls['index'].invalid">
            Index v rámci uzlu může být hodnota 0-9
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="d-flex">
          <mat-label>Popis</mat-label>
          <input matInput type="text" formControlName="description">
        </mat-form-field>
        <mat-action-row>
          <button mat-button type="submit" [disabled]="submitted || !form.valid" color="primary">
            <mat-icon class="mr-2">save</mat-icon>Uložit
          </button>
          <mat-error *ngIf="this.form.invalid && (this.form.dirty || this.form.touched)">
            <mat-icon class="mr-2" matTooltip="Formulář obsahuje chyby">warning</mat-icon>
          </mat-error>
        </mat-action-row>
      </mat-expansion-panel>
    </form>
    <form [formGroup]="permAssignForm" *ngIf="meter.id">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>Oprávnění k měřáku</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div formArrayName="permissions">
          <div *ngFor="let permission of permissions.controls; let i = index">
            <div [formGroupName]="i">
              <mat-form-field appearance="fill" class="mr-2">
                <mat-label>Uživatel</mat-label>
                <mat-select [formControl]="permission['controls']['user']" required>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let user of users" [value]="user.id">
                    {{ user.username }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Oprávnění</mat-label>
                <mat-select [formControl]="permission['controls']['permission']" required>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let perm of meterPermissions" [value]="perm">
                    {{ perm }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-icon-button (click)="savePermission(i)" color="primary" matTooltip="Uložit oprávnění" class="align-middle"><mat-icon>save</mat-icon></button>
              <button mat-icon-button (click)="removePermission(i)" color="warn" matTooltip="Smazat oprávnění" class="align-middle"><mat-icon>delete</mat-icon></button>
            </div>
          </div>
        </div>
        <button mat-icon-button (click)="addPermision()" color="primary" matTooltip="Přidat oprávnění"><mat-icon>add_box</mat-icon></button>
        <mat-error *ngIf="this.permAssignForm.dirty || this.permAssignForm.touched">
          <mat-icon class="mr-2">warning</mat-icon>Nezapomeňte uložit nové oprávnění!
        </mat-error>
      </mat-expansion-panel>
    </form>
  </mat-accordion>
</div>
<div mat-dialog-actions class="mt-2">
  <button mat-raised-button mat-dialog-close>Zpět</button>
</div>
