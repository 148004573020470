import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {GroupService} from '../../../services/data/group.service';
import {MessagerService} from '../../../services/messager.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Group} from '../../../models/http/group/group.model';
import {AbstractControl, Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Logger, LoggingService} from '../../../services/logging.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  title: string;
  groups: Group[];
  private logger: Logger;
  private subscriptions: Subscription[] = [];
  constructor(
    private groupService: GroupService,
    private messager: MessagerService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<GroupEditComponent>,
    private logging: LoggingService,
    @Inject(MAT_DIALOG_DATA) private group: Group,
  ) {
    this.logger = this.logging.getLogger(this.constructor.name);
  }

  ngOnInit(): void {
    this.title = (this.group.id) ? ('Editace skupiny ' + this.group.name) : 'Vytvořit novou skupinu';
    this.form = this.fb.group({
     id: [this.group.id, Validators.nullValidator],
     name: [this.group.name, Validators.required],
     parentId: [this.group.parentId, Validators.nullValidator]}
    );
    this.subscriptions.push(this.groupService.getAll().subscribe((groups: Group[]) => {
      if (groups && groups.length) {
        this.groups = groups;
      } else {
        this.groups = [];
        this.messager.warning('Nebyl načten seznam skupin.');
      }
    }, error1 => {
      this.messager.error('Nepodařilo se načíst seznam skupin.');
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }
  }

  get formControls(): any { return this.form.controls; }

  onSubmit(): void {
    if (this.group.id) {
      // save edited group
      this.subscriptions.push(this.groupService.modify(this.form.value).subscribe((value: Group) => {
        if (value.id) {
          this.messager.success(`Skupina ${value.name} uložena`);
          this.dialogRef.close(true);
        } else {
          this.messager.warning(`Nepodařilo se uložit skupinu.`);
        }
      }, error1 => {
        this.messager.error('Nepodařilo se uložit skupinu.');
        this.logger.error(error1);
      }));
    } else {
      // create new group
      const newGroup: Group = Object.assign(new Group(), this.form.value);
      delete newGroup.id;
      this.subscriptions.push(this.groupService.create(newGroup).subscribe((value: any) => {
        if (value.id) {
          this.messager.success('Skupina byla vytvořena.');
          this.dialogRef.close(true);
        }
      }, error1 => {
        this.messager.error('Skupinu se nepodařilo vytvořit.');
        this.logger.error(error1);
      }));
    }
  }

}
