import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppStateManager} from '../../../services/app-state-manager.service';
import {Subscription} from 'rxjs';
import {PageTitle} from '../../../interfaces/app-page.interface';

@Component({
  selector: 'app-current-page',
  templateUrl: './current-page.component.html',
  styleUrls: ['./current-page.component.scss']
})
export class CurrentPageComponent implements OnInit, OnDestroy {
  public currentPage: PageTitle = {
    icon: '',
    name: ''
  };
  public subscriptions: Subscription;
  constructor(
    private appStateService: AppStateManager
  ) { }

  ngOnInit(): void {
    this.subscriptions = this.appStateService.getCurrentPage().subscribe((value: PageTitle) => {
      this.currentPage = value;
    }, (error1 => {
      console.log('Nepodařilo se nastavit titulek stránky');
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }



}
