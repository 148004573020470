import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/http/user.model';
import {BaseHttpService} from '../base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseHttpService<User> {
  constructor(
    private httpC: HttpClient
  ) {
    super(httpC, User);
  }
}
