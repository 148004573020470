import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Meter} from '../../../models/http/meter/meter.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {MapHelpersService} from '../../../services/map-helpers.service';
import MarkerOptions = google.maps.MarkerOptions;
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-meters-map',
  templateUrl: './meters-map.component.html',
  styleUrls: ['./meters-map.component.scss']
})
export class MetersMapComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription[] = [];
  mapMarkers: MarkerOptions[];
  mapInfoWindowOpened = false;
  clickedMarkerIndex = 0;
  public mapCenter: google.maps.LatLngLiteral = {
    lng: 15.695535293,
    lat: 49.896753749
  };
  public mapOptions: google.maps.MapOptions = {
    fullscreenControl: true,
    mapTypeControl: false,
    streetViewControl: false
  };

  @ViewChild('metersMap') metersMap: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  constructor(
    private dialogRef: MatDialogRef<MetersMapComponent>,
    private mapHelpers: MapHelpersService,
    @Inject(MAT_DIALOG_DATA) public meters: Meter[],
  ) {
    this.mapMarkers = this.mapHelpers.getMarkerOptions(this.meters);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.metersMap.fitBounds(this.mapHelpers.getMapBoundsByMarkers(this.mapMarkers));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }
  }

  openInfoWindow(marker: MapMarker, markerIndex: number): void {
    this.clickedMarkerIndex = markerIndex;
    this.mapInfoWindowOpened = true;
    this.infoWindow.open(marker);
  }

  closeInfoWindow(): void {
    this.mapInfoWindowOpened = false;
  }

}
