import {SocketModel} from './socket.model';

/**
 * Base model class for readings from the Meter
 */
export class ClientMeterReadingBase extends SocketModel{
  MSG_ID: string;
  MSG_CTR: number;
  DEVICE_ID: number;
  METER_ID: number;
  VALUE: number;
  TIMESTAMP: string;
}
