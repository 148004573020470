import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MessagerService} from '../../../services/messager.service';
import {Logger, LoggingService} from '../../../services/logging.service';
import {AppConfig} from '../../../app.config';
import {Subscription, of} from 'rxjs';
import {MatTable} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {GroupService} from '../../../services/data/group.service';
import {MatDialog} from '@angular/material/dialog';
import {CommonsService} from '../../../services/commons.service';
import {Group} from '../../../models/http/group/group.model';
import {GroupEditComponent} from '../group-edit/group-edit.component';
import {GroupDetailComponent} from '../group-detail/group-detail.component';
import {Filters} from '../../../models/filters';
import {NestedTreeControl} from '@angular/cdk/tree';
import {GroupsTreeDataSource} from '../../data-sources/groups-tree.data-source';

@Component({
  selector: 'app-group-uni-table',
  templateUrl: './group-uni-tree.component.html',
  styleUrls: ['./group-uni-tree.component.scss']
})
export class GroupUniTreeComponent implements OnInit, AfterViewInit, OnDestroy {
  logger: Logger;
  dataSource: GroupsTreeDataSource;
  subscriptions: Subscription[] = [];
  defaultPageSize = AppConfig.DEFAULT_PAGE_SIZE;
  pageSizes = AppConfig.DEFAULT_PAGE_SIZES;

  nestedTreeControl: NestedTreeControl<Group>;

  private filters: Filters = new Filters();
  private tablePageSize: number;

  // Set to true if table should provide user interface for administration
  @Input() isAdmin = false;

  @ViewChild('groupsTable') groupsTable: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private groupService: GroupService,
    private logging: LoggingService,
    private messager: MessagerService,
    private commons: CommonsService,
    private dialog: MatDialog,
  ) {
    this.logger = this.logging.getLogger(this.constructor.name);
  }

  ngOnInit(): void {
    this.dataSource = new GroupsTreeDataSource(this.groupService, this.messager, this.logging);
    this.nestedTreeControl = new NestedTreeControl<Group>(this.getChildren);
  }

  ngAfterViewInit(): void {
    this.getGroupsPage();

  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }
  }

  private getChildren = (node: Group) => of(node.children);

  hasNestedChild = (_: number, nodeData: Group) => nodeData.children.length > 0;

  private getGroupsPage(): void {
    this.filters.setPage(this.paginator.pageIndex)
      .setPageSize(this.paginator.pageSize || this.defaultPageSize)
      .setSortCol(this.sort.active)
      .setSortDir(this.sort.direction);
    this.dataSource.getGroupsPage(this.filters);
  }

  /**
   * Delete group via GroupService
   * @param groupId
   */
  private deleteGroup(groupId: number): void {
    this.subscriptions.push(this.groupService.delete(groupId).subscribe((res) => {
      if (res) {
        this.messager.success('Skupina smazána.');
        this.getGroupsPage();
      } else {
        this.messager.error('Skupina nebyla smazána. Chyba: ' + res.error);
      }
    }, error1 => {
      this.messager.error('Skupina nebyla smazána. Chyba: ' + error1);
    }));
  }

  // CLICK HANDLING METHODS

  onUpdatePageSize(event: PageEvent): void {
    if (event.pageSize !== this.tablePageSize || event.pageIndex !== event.previousPageIndex) {
      this.tablePageSize = event.pageSize;
      this.getGroupsPage();
    }
  }

  /**
   * User wants to add new group - open dialog
   */
  onAddClick(): void {
    this.dialog.open(GroupEditComponent, {
      hasBackdrop: true,
      panelClass: ['edit-dialog', 'medium-wide'],
      data: new Group()
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.getGroupsPage();
      }
    });
  }

  /**
   * Opens detail dialog of the group
   * @param group Group
   */
  onDetailClick(group: Group): void {
    this.dialog.open(GroupDetailComponent, {
      hasBackdrop: true,
      panelClass: ['detail-dialog', 'wide'],
      data: group
    });
  }

  /**
   * Opens edit dialog of the group
   * @param group Group
   */
  onEditClick(group: Group): void {
    this.dialog.open(GroupEditComponent, {
      hasBackdrop: true,
      panelClass: ['edit-dialog', 'medium-wide'],
      data: group
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.getGroupsPage();
      }
    });
  }

  /**
   * User wants to delete the group - show confirmation dialog and delete if confirmed
   * @param group Group Group to be deleted.
   */
  onDeleteClick(group: Group): void {
    this.commons.createConfirmDialog('Opravdu smazat skupinu ' + group.name + ' ?')
      .subscribe(value => {
        if (value) {
          this.deleteGroup(group.id);
        }
      });
  }

  sortData(sort: Sort): void {
    this.getGroupsPage();
  }
}
