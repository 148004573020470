import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Logger, LoggingService} from '../../../services/logging.service';
import {MetersTableDataSource} from '../../data-sources/meters-table.data-source';
import {AppConfig} from '../../../app.config';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MeterService} from '../../../services/data/meter.service';
import {MessagerService} from '../../../services/messager.service';
import {CommonsService} from '../../../services/commons.service';
import {MatDialog} from '@angular/material/dialog';
import {Meter} from '../../../models/http/meter/meter.model';
import {MeterEditComponent} from '../meter-edit/meter-edit.component';
import {MeterDetailComponent} from '../meter-detail/meter-detail.component';
import {WebsocketService} from '../../../services/socket/websocket.service';
import {GlobalFiltersService} from '../../../services/global-filters.service';
import {Subscription} from 'rxjs';
import {MatTable} from '@angular/material/table';
import {MetersMapComponent} from '../meters-map/meters-map.component';
import {Filters} from '../../../models/filters';

@Component({
  selector: 'app-meter-uni-table',
  templateUrl: './meter-uni-table.component.html',
  styleUrls: ['./meter-uni-table.component.scss']
})
export class MeterUniTableComponent implements OnInit, AfterViewInit, OnDestroy {
  logger: Logger;
  dataSource: MetersTableDataSource;
  subscriptions: Subscription;
  displayedColumns = ['title'];
  defaultPageSize = AppConfig.DEFAULT_PAGE_SIZE;
  pageSizes = AppConfig.DEFAULT_PAGE_SIZES;
  dataExportFormats = AppConfig.DATA_EXPORT_FORMATS;
  // options for excel export by mat-table-exporter
  exportOptions = {
    fileName: 'eOdecet'
  };

  private filters: Filters = new Filters();
  private tablePageSize: number;

  // Set to true if table should provide user interface for administration
  @Input() isAdmin = false;
  // Set to true if show power and consumption data
  @Input() showReadings = false;
  // If true, button to show meters on map is rendered
  @Input() showMapButton = true;
  // If the group id passed, only meters belonging to this group will be loaded
  @Input() group: number[] = [];
  // If the device id is passed, only meter belonging to this device will be loaded
  @Input() device: number = null;
  // If true is passed, table will load just offline meters
  @Input() justOffline = false;
  // If true is passed, export to excel is allowed, button shown
  @Input() allowExport = false;

  @ViewChild('metersTable') metersTable: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private meterService: MeterService,
    private ws: WebsocketService,
    private globalFilters: GlobalFiltersService,
    private messager: MessagerService,
    private logging: LoggingService,
    private commons: CommonsService,
    private dialog: MatDialog
  ) {
    this.logger = this.logging.getLogger(this.constructor.name);
  }

  ngOnInit(): void {
    this.dataSource = new MetersTableDataSource(this.meterService, this.ws, this.messager, this.globalFilters);
    if (this.isAdmin) {
      this.displayedColumns.push('index');
    }
    if (this.showReadings) {
      this.displayedColumns.push('power', 'consumption', 'consumptionInPeriod');
    }
    this.displayedColumns.push('actions', 'states');
  }

  ngAfterViewInit(): void {
    this.getMetersPage();
    this.exportOptions.fileName = `eOdecet_${this.globalFilters.getDateFromFormatted()}-${this.globalFilters.getDateToFormattted()}`;
    if (this.showReadings) {
      this.subscriptions = this.globalFilters.filterChanged$.subscribe((next => {
        if (next) {
          this.getMetersPage();
          this.exportOptions.fileName = `eOdecet_${this.globalFilters.getDateFromFormatted()}-${this.globalFilters.getDateToFormattted()}`;
        }
      }));
    }
  }

  private getMetersPage(): void {
    const groups = this.group.length ? this.group : this.globalFilters.getGroups();
    this.filters.setPage(this.paginator.pageIndex)
      .setPageSize(this.paginator.pageSize || this.defaultPageSize)
      .setDateFrom(this.globalFilters.getDateFromFormatted())
      .setDateTo(this.globalFilters.getDateToFormattted());
    if (this.sort.active) {
      this.filters.setSortCol(this.sort.active)
        .setSortDir(this.sort.direction);
    }
    if (this.filters.getGroups() && !groups.length) {
      this.filters.setGroups(null);
    } else if (groups.length) {
      // check once again, because the global group filter can be empty also
      this.filters.setGroups(this.globalFilters.getGroups());
    }
    if (this.device) {
      this.filters.setDevice(this.device);
    }
    if (this.justOffline) {
      this.filters.setOnline(false);
    }
    this.dataSource.getMetersPage(this.filters);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.dataSource.unsubscribeFromReadings();
  }

  /**
   * Handles click on add meter button
   */
  onAddClick(): void {
    this.dialog.open(MeterEditComponent, {
      data: new Meter(),
      hasBackdrop: true,
      panelClass: ['edit-dialog', 'medium-wide']
    }).afterClosed().subscribe((value => {
      if (value) {
        this.getMetersPage();
      }
    }));
  }

  /**
   * Handles click on meter view details button
   */
  onDetailClick(meter: Meter): void {
    this.dialog.open(MeterDetailComponent, {
      data: meter,
      hasBackdrop: true,
      panelClass: ['detail-dialog', 'wide']
    });
  }

  /**
   * Handles click on meter edit button
   * @param meter
   */
  onEditClick(meter: Meter): void {
    this.dialog.open(MeterEditComponent, {
      data: meter,
      hasBackdrop: true,
      panelClass: ['edit-dialog', 'wide']
    }).afterClosed().subscribe(() => {
      this.getMetersPage();
    });
  }

  /**
   * Handles click on meter delete button
   * @param meter
   */
  onDeleteClick(meter: Meter): void {
    this.commons.createConfirmDialog('Opravdu smazat elektroměr ' + meter.title + '?')
      .subscribe(value => {
        if (value) {
          this.deleteMeter(meter);
        }
      });
  }

  onShowMapClick(): void {
    this.dialog.open(MetersMapComponent, {
      data: this.dataSource.getData(),
      hasBackdrop: true,
      panelClass: ['detail-dialog', 'full']
    });
  }

  /**
   * Tries to delete given meter
   * @param meter
   */
  private deleteMeter(meter: Meter): void {
    this.meterService.delete(meter.id).subscribe((value) => {
      this.messager.success('Elektroměr ' + meter.title + ' smazán');
      this.getMetersPage();
    }, error1 => {
      this.messager.error('Elektroměr ' + meter.title + ' se nepodařilo smazat. Chyba: ' + error1);
    });
  }

  onUpdatePageSize(event: PageEvent): void {
    if (event.pageSize !== this.tablePageSize || event.pageIndex !== event.previousPageIndex) {
      this.tablePageSize = event.pageSize;
      this.getMetersPage();
    }
  }

  onSortChange(event: Sort): void {
    this.getMetersPage();
  }

}
