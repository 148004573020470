import {Deserializable} from '../../interfaces/deserializable.interface';

export class HttpModel implements Deserializable{
  public totalCount: number;
  constructor() { }

  public deserialize(input: any): this {
    if (input) {
      Object.assign(this, input);
    }
    return this;
  }
}
