import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-heat',
  templateUrl: './dashboard-heat.component.html',
  styleUrls: ['./dashboard-heat.component.scss']
})
export class DashboardHeatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
