import { Injectable } from '@angular/core';
import {BaseHttpService} from '../base-http.service';
import {Meter} from '../../models/http/meter/meter.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {MeterAssignment} from '../../models/http/meter/meter-assignment.model';
import {MeterAssignmentResponse} from '../../models/http/meter/meter-assignment-response.model';
import * as moment from 'moment';
import {AppConfig} from '../../app.config';
import {Filters} from '../../models/filters';

@Injectable({
  providedIn: 'root'
})
export class MeterService extends BaseHttpService<Meter> {

  constructor(private httpC: HttpClient) {
    super(httpC, Meter);
  }

  /**
   * Returns observable with list of Meters with applied date selection for readings
   * @param dateFrom
   * @param dateTo
   * @param groups
   * @param page
   * @param pageSize
   */
  public getMetersWithReadingsWithFilters(filters: Filters): Observable<Meter[]> {
    const params = { params: new HttpParams() };
    if (filters.hasFilters()) {
      filters.getFilters().forEach((value: any, key: string) => {
          params.params = params.params.set(key, value);
      });
    }

    return this.httpC.get(this.endpoint, params)
      .pipe(
        map((res: any) => res.map((entity: Meter) => (new Meter()).deserialize(entity)))
      );
  }

  /**
   * Gets meter with readings for specified dates range
   * @param meterId Id of the meter to load
   * @param dateFrom
   * @param dateTo
   */
  public getMeterWithReadings(meterId: number, dateFrom: moment.Moment, dateTo: moment.Moment): Observable<Meter> {
    const url = `${this.endpoint}/${meterId}?from=${dateFrom.toISOString().split('.')[0]}&to=${dateTo.toISOString().split('.')[0]}`;
    return this.httpC.get(url)
      .pipe(
        map((value: Meter) => {
          value = new Meter().deserialize(value);
          return value;
        })
      );
  }

  /**
   * Adds new permission assignment to the meter
   * @param assignment
   */
  public addMeterAssignment(assignment: MeterAssignment): Observable<MeterAssignmentResponse> {
    return this.httpC.post(this.endpoint + '/assignment', assignment)
      .pipe(
        tap((next: MeterAssignmentResponse) => new MeterAssignmentResponse().deserialize(next))
      );
  }

  /**
   * Removes user permission from the meter
   * @param assignment
   */
  public removeDeviceAssignment(assignment: MeterAssignment): Observable<MeterAssignmentResponse> {
    const url = `${this.endpoint}/assignment/${assignment.meterId}?userId=${assignment.userId}`;
    return this.httpC.delete(url)
      .pipe(
        tap((next: MeterAssignmentResponse) => new MeterAssignmentResponse().deserialize(next))
      );
  }
}
