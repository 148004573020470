import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private tasks: Set<string> = new Set<string>();
  display: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading = new BehaviorSubject(false);
  constructor() { }

  start(): string {
    const ident = this.createIdentifier();
    this.tasks.add(ident);
    this.emit();
    return ident;
  }

  end(ident: string): void {
    this.tasks.delete(ident);
    this.emit();
  }

  reset(): void {
    this.tasks.clear();
    this.emit();
  }

  private emit(): void {
    this.display.next(this.tasks.size !== 0);
  }

  private createIdentifier(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  }
}
