<h2 mat-dialog-title>
  Detail skupiny {{ data.name }}
</h2>
<div mat-dialog-content class="pb-3">
  <table class="table detail-table">
    <tr>
      <td>Id</td>
      <td>{{ data.id }}</td>
    </tr>
    <tr>
      <td>Nadřazená skupina</td>
      <td>
        <span *ngIf="!data.parentId">skupina nemá nadřazenou skupinu</span>
        <span *ngIf="data.parentId">{{ this.getGroupById(data.parentId).name }}</span>
      </td>
    </tr>
    <tr>
      <td>Podřízené skupiny</td>
      <td>
        <div *ngIf="data.children.length">
          <span class="d-block" *ngFor="let group of data.children">
          {{ group.name }} ({{group.id}})
          </span>
        </div>
        <span *ngIf="!data.children.length">
          Skupina nemá žádné podřízené skupiny.
        </span>
      </td>
    </tr>
  </table>
  <mat-accordion>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <strong>Měřiče ve skupině</strong>
      </mat-expansion-panel-header>
      <app-meter-uni-table
        [group]="[this.data.id]"></app-meter-uni-table>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div mat-dialog-actions class="mt-2">
  <button mat-button mat-dialog-close>
    Zavřít
  </button>
</div>
