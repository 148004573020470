import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

/**
 * The goal of this interceptor is to remove endpoint property from the request body.
 * The endpoint property is used to easily distinguish backend endpoint based on
 * model name. However it can not be part of the request body.
 */
@Injectable({
  providedIn: 'root'
})
export class RequestFilterInterceptorService implements HttpInterceptor{

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let resultReq: HttpRequest<any>;
    if (req.body) {
      const cloned: HttpRequest<any> = req.clone();
      if (cloned.body.hasOwnProperty('endpoint')) {
        delete cloned.body.endpoint;
      }
      resultReq = cloned;
    } else {
      resultReq = req;
    }

    return next.handle(resultReq);
  }
}
