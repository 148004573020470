<mat-toolbar color="primary" class="mat-primary mat-toolbar-single-row" *ngIf="(auth.isLoggedIn() | async)">
  <div class="row w-100">
    <div class="col-6">
      <button mat-icon-button aria-label="Show/hide navigation" [matMenuTriggerFor]="mainNavigation">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #mainNavigation="matMenu">
        <button mat-menu-item routerLink="/dashboard">
          <mat-icon>home</mat-icon>
          <span>Dashboard</span>
        </button>
        <button mat-menu-item routerLink="/nastaveni" *appHasRole="'ADMIN'">
          <mat-icon>settings_applications</mat-icon>
          <span>Nastavení</span>
        </button>
      </mat-menu>
      <img src="../assets/images/eOdecet_logo_bily_text.png" alt="eOdečet.cz" class="ml-2">
    </div>
    <div class="col-6 d-flex justify-content-end align-items-center">
      <div class="user-menu" *ngIf="(auth.isLoggedIn() | async) && this.currentUser.username">
        <button mat-icon-button aria-label="Show/hide user menu" [matMenuTriggerFor]="userMenu">
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #userMenu="matMenu">
          <p class="p-3"><strong>{{ this.currentUser.username }}</strong></p>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="this.onUserEditClick(this.currentUser)">
            <mat-icon>settings</mat-icon>
            <span>Nastavení profilu</span>
          </button>
          <button mat-menu-item (click)="this.onLogoutClick()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Odhlásit se</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
<app-current-page></app-current-page>
<app-loading-indicator></app-loading-indicator>
<app-global-filters *ngIf="(auth.isLoggedIn() | async) && this.currentUser.username"></app-global-filters>
<div class="row mx-0 d-flex flex-column">
  <div class="col">
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </div>
  <app-copyright class="col" *ngIf="(auth.isLoggedIn() | async)"></app-copyright>
</div>
