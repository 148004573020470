import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit{

  @Input() appHasRole: string;

  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit(): void {
    let result = null;

    for (const r of this.appHasRole.split('||')) {
      result = result === null ? this.checkRole(r) : result || this.checkRole(r);
    }

    if (result) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  /**
   * Checks whether user has specified role
   * @param role Role to check
   */
  private checkRole(role: string): boolean {
    let res = false;
    if (this.auth.isLoggedIn()) {
      const roles = this.auth.getRoles();

      if (roles.indexOf(role) > -1) {
        res = true;
        console.log('role found');
      }
    }
    console.log('Check role result:');
    console.log(res);
    return res;
  }

}
