import {HttpModel} from '../http.model';
import {Reading} from '../reading/reading.model';

export class Device extends HttpModel {
  id: number;
  mac: string;
  owners: Map<string, string>;
  description: string;
  gpsLatitude: number;
  gpsLongitude: number;
  group: number;
  ip: string;
  specification: string;
  updatedAt: string;
  readingPower: Reading[];
  readingConsumption: Reading[];
}
