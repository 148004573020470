<form [formGroup]="form" (ngSubmit)="onSubmit()" class="edit-form">
  <h2 mat-dialog-title>{{ this.title }}</h2>
    <div mat-dialog-content class="d-flex flex-column">
      <mat-form-field appearance="outline" class="d-flex">
        <mat-label>Popis</mat-label>
        <input matInput type="text" formControlName="description" required>
        <mat-error *ngIf="this.formControls['description'].invalid && this.formControls['description'].errors.required">
          Vyplňte prosím popis zařízení
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="d-flex">
        <mat-label>MAC adresa</mat-label>
        <input matInput type="text" formControlName="mac" required>
        <mat-error *ngIf="this.formControls['mac'].invalid && this.formControls['mac'].errors.required">
          Vyplňte prosím MAC adresu
        </mat-error>
      </mat-form-field>
      <div class="d-flex flex-row">
        <mat-form-field appearance="outline" class="d-flex flex-fill mr-3">
          <mat-label>Zeměpisná šířka</mat-label>
          <input matInput type="text" formControlName="gpsLatitude" required>
          <mat-error *ngIf="this.formControls['gpsLatitude'].invalid && this.formControls['gpsLatitude'].errors.required">
            Vyplňte prosím zeměpisnou šířku
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="d-flex flex-fill">
          <mat-label>Zeměpisná délka</mat-label>
          <input matInput type="text" formControlName="gpsLongitude" required>
          <mat-error *ngIf="this.formControls['gpsLongitude'].invalid && this.formControls['gpsLongitude'].errors.required">
            Vyplňte prosím zeměpisnou délku
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 px-0 mt-0 mb-4">
        <button type="button" mat-raised-button color="primary" (click)="selectPositionFromMap()">
          <i class="material-icons">edit_location</i>
          Vybrat polohu uzlu na mapě
        </button>
      </div>
      <mat-form-field appearance="outline" class="d-flex mt-3">
        <mat-label>Skupina, do které uzel patří</mat-label>
        <mat-select formControlName="group">
          <mat-option *ngFor="let group of groups" [value]="group.id">
            {{ group.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="this.formControls['group'].invalid">
          Vyberte prosím jednu platnou skupinu
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="d-flex">
        <mat-label>Specifikace</mat-label>
        <input matInput type="text" formControlName="specification">
      </mat-form-field>
    </div>
  <div mat-dialog-actions class="mt-2">
    <button mat-button type="submit" [disabled]="submitted || !form.valid" color="primary">
      <mat-icon class="mr-2">save</mat-icon>Uložit
    </button>
    <mat-error *ngIf="this.form.invalid && (this.form.dirty || this.form.touched)">
      <mat-icon class="mr-2" matTooltip="Formulář obsahuje chyby">warning</mat-icon>
    </mat-error>
    <button mat-raised-button mat-dialog-close>Zpět</button>
  </div>
</form>
