<div *ngIf="!isOpened">
  <button mat-button (click)="toggle()" color="primary" class="global-filters show-button mat-elevation-z6" matTooltip="Otevřít filtry">
    <mat-icon>filter_alt</mat-icon>
  </button>
</div>
<mat-card class="global-filters mat-elevation-z6 pr-4" [@showHide]="isOpened ? 'show' : 'hidden'">
  <mat-card-header>
    <mat-icon mat-card-avatar>filter_alt</mat-icon>
    <mat-card-title>Filtry</mat-card-title>
  </mat-card-header>
  <mat-card-content class="d-flex flex-column">
    <div class="d-flex flex-column">
      <strong>Období zobrazení dat</strong>
      <mat-form-field appearance="outline" class="mt-3">
        <mat-label>Datum od</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (dateChange)="changeDateFrom($event)" [formControl]="dateFrom">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Datum do</mat-label>
        <input matInput [matDatepicker]="pickerTo" (dateChange)="changeDateTo($event)" [formControl]="dateTo">
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="d-flex flex-column">
      <strong>Skupina</strong>
      <mat-form-field appearance="outline" class="mt-2">
        <mat-label>Vyberte skupinu</mat-label>
        <mat-select (selectionChange)="onGroupSelection($event)" multiple>
          <mat-option *ngFor="let group of groups" [value]="group.id">
            {{ group.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button (click)="toggle()" color="primary" class="d-flex" matTooltip="Skrýt filtry">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
