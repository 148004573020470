import {Deserializable} from '../../interfaces/deserializable.interface';

/**
 * Base model class for app models handled through WebSocket
 */
export abstract class SocketModel implements Deserializable {
  public readonly request: string;
  public requestId: string;

  constructor() {
    const action = this.constructor.name.charAt(0).toLowerCase() + this.constructor.name.slice(1);
    // remeco# is the Remote Meters Controls application request identifier
    this.request = 'MSG_ID#' + action;
  }

  public deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}
