import {Deserializable} from '../deserializable.model';

export class MeterAssignmentResponse implements Deserializable {
  id: number;
  title: string;
  deviceId: string;
  index: number;
  description: string;
  owners: Map<string, string>;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
