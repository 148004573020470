import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

  constructor(
    private auth: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loginToken = this.auth.getToken();
    if (loginToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization',
          'Bearer ' + loginToken)
      });

      return next.handle(cloned);
    }

    return next.handle(req);
  }


}
