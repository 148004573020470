import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, Subject, Subscriber, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {Filters} from '../models/filters';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService<T> {
  public totalCount = new Subject<number>();
  protected endpoint: string;
  constructor(
    private http: HttpClient,
    private type: new () => T
  ) {
    const cName = this.constructor.name;
    this.endpoint = (environment.backendHttpUrl) + (cName.substr(0, cName.indexOf('Service')).toLowerCase());
  }

  protected handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // client-side or network error - request not sent
      console.error('Došlo k chybě při posílání požadavku na server:', error.error.message);
    } else {
      // the backend returned unsuccessful error code
      console.error(`Back-end server vrátil chybu: ${error.status}, zpráva: ${error.error}`);
    }

    return throwError(error.message);
  }

  /**
   * Gets total count of items of given entity from server
   */
  public getTotalCount(): void{
    this.http.get(`${this.endpoint}?page=0&pageSize=1`, {observe: 'response'})
      .subscribe(
        (value) => {
          this.totalCount.next(parseInt(value.headers.get('total-count'), 10));
        }
      );
  }

  /**
   * Get objects of given entity with pagination filters index and limit
   * @param filters Filters The filters for the http query
   * @param pageSize Size of the page to load
   */
  public getWithFilters(filters: Filters): Observable<T[]> {
    const params = { params: new HttpParams() };
    if (filters.hasFilters()) {
      filters.getFilters().forEach((value: string, key: string) => {
        params.params = params.params.set(key, value);
      });
    }

    return this.http.get(this.endpoint, params)
      .pipe(
        map((res: any) => res.map((entity: T) => (new this.type() as any).deserialize(entity)))
      );
  }

  /**
   * Gets object of given entity of the given ID from the server
   * @param id Id of the device to get
   */
  public getSingle(id: number|string): Observable<T> {
    return this.http.get(this.endpoint + '/' + id)
      .pipe(
        tap((res: T) => (new this.type() as any).deserialize(res))
      );
  }

  /**
   * Gets all devices from the server
   */
  public getAll(): Observable<T[]> {
    return this.http.get(this.endpoint)
      .pipe(
        map((res: any) => res.map((ent: T) => (new this.type() as any).deserialize(ent))
        ));
  }

  /**
   * Adds new item of given entity
   * @param entity T Object of the entity to add
   */
  public create(entity: T): Observable<T> {
    return this.http.post(this.endpoint, entity)
      .pipe(
        tap((next: T) => (new this.type() as any).deserialize(next))
      );
  }

  /**
   * Modifies item of given entity type on the server
   * @param entity Modified entity object
   */
  public modify(entity: T): Observable<T> {
    return this.http.put(this.endpoint, entity)
      .pipe(
        tap((next: T) => (new this.type() as any).deserialize(next))
      );
  }

  /**
   * Deletes item of given ID of particular entity type from the server
   * @param id Id of the device to delete
   */
  public delete(id: number|string): Observable<any> {
    return this.http.delete(this.endpoint + '/' + id);
  }
}
