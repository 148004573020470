import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import * as moment from 'moment';
import {AppConfig} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class GlobalFiltersService {
  // starting date for global filters - set one day to past at default
  private dateFrom = new BehaviorSubject<moment.Moment>(moment().subtract(1, 'day'));
  public dateFrom$ = this.dateFrom.asObservable();
  // end date for global filters - set on now at default
  private dateTo = new BehaviorSubject<moment.Moment>(moment());
  public dateTo$ = this.dateTo.asObservable();
  private filterChanged = new Subject<boolean>();
  public filterChanged$ = this.filterChanged.asObservable();

  // group selection
  private group = new BehaviorSubject<number[]>([]);

  constructor() { }

  public setDateFrom(date: moment.Moment): this {
    this.dateFrom.next(date);
    this.filterChanged.next(true);
    return this;
  }

  public setDateTo(date: moment.Moment): this {
    this.dateTo.next(date);
    this.filterChanged.next(true);
    return this;
  }

  public getDateFrom(): moment.Moment {
    return this.dateFrom.getValue();
  }

  public getDateFromFormatted(): string {
    return this.dateFrom.getValue().format(AppConfig.DEFAULT_TIMESTAMP_FORMAT);
  }

  public getDateTo(): moment.Moment {
    return this.dateTo.getValue();
  }

  public getDateToFormattted(): string {
    return this.dateTo.getValue().format(AppConfig.DEFAULT_TIMESTAMP_FORMAT);
  }

  public setGroups(ids: number[]): this {
    this.group.next(ids);
    this.filterChanged.next(true);
    return this;
  }

  public getGroups(): number[] {
    return this.group.getValue();
  }
}
