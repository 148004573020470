import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {MessagerService} from './messager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authentication: AuthService,
    private router: Router,
    private messager: MessagerService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {
     const url = state.url;

     return this.checkLogin(url);
  }

  // If user is not logged in, redirect to login page
  checkLogin(url: string): true|UrlTree {
    if (this.authentication._isLoggedIn()) { return true; }

    // Save the requested url for redirect
    this.authentication.requestedUrl = (url !== '/') ? url : null;

    // redirect to login page
    return this.router.parseUrl('/login');
  }

}
