import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../services/data/user.service';
import {MessagerService} from '../../../services/messager.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Meter} from '../../../models/http/meter/meter.model';
import {MeterService} from '../../../services/data/meter.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {GlobalFiltersService} from '../../../services/global-filters.service';
import {ClientMeterReadingPower} from '../../../models/socket/client-meter-reading-power.model';
import {WebsocketService} from '../../../services/socket/websocket.service';
import {ClientMeterReadingConsumption} from '../../../models/socket/client-meter-reading-consumption.model';
import {ChartDataSets, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import * as moment from 'moment';
import {AppConfig} from '../../../app.config';
import {ReadingService} from '../../../services/data/reading.service';
import {Reading} from '../../../models/http/reading/reading.model';
import {ClientMeterOnline} from '../../../models/socket/client-meter-online.model';

@Component({
  selector: 'app-meter-detail',
  templateUrl: './meter-detail.component.html',
  styleUrls: ['./meter-detail.component.scss']
})
export class MeterDetailComponent implements OnInit, OnDestroy {
  meter: Meter = new Meter();
  subscription: Subscription[] = [];
  // Date from/to for data acquisition
  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  // Map settings
  mapCenter: google.maps.LatLngLiteral;
  mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false
  };

  // Real-time power chart settings
  public powerChartOptions = {
    responsive: true,
  };
  public chartType: ChartType = 'line';
  public chartData: ChartDataSets[] = [
    { data: [], label: 'Příkon (kW)' }
  ];
  public chartLabels: Label[] = [];

  // Historic power chart settings
  public histPowerChartData: ChartDataSets[] = [
    { data: [], label: 'Příkon (kW)' }
  ];
  public histPowerChartLabels: Label[] = [];

  // Historic consumption chart settings
  public consumpChartType: ChartType = 'line';
  public consumpChartOptions = {
    responsive: true,
  };
  public histConsumpChartData: ChartDataSets[] = [
    { data: [], label: 'Spotřeba (kWh)' }
  ];
  public histConsumpChartLabels: Label[] = [];

  constructor(
    private wsService: WebsocketService,
    private meterService: MeterService,
    private readingService: ReadingService,
    private userService: UserService,
    private globalFilters: GlobalFiltersService,
    private messager: MessagerService,
    private dialog: MatDialogRef<MeterDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Meter
  ) {
  }

  ngOnInit(): void {
    this.mapCenter = {
      lat: this.data.gpsLatitude,
      lng: this.data.gpsLongitude
    };
    this.subscription.push(this.globalFilters.dateFrom$.subscribe((value) => this.dateFrom = value));
    this.subscription.push(this.globalFilters.dateTo$.subscribe((value) => this.dateTo = value));

    this.getMeterData();
    this.getReadingsForPowerChart();
    this.getReadingsForConsumptionChart();
    this.subscribeToWsReadings();
  }

  ngOnDestroy(): void {
    if (this.subscription.length) {
      this.subscription.forEach((value: Subscription) => value.unsubscribe());
    }
  }

  /**
   * Get data of meter (readings in selected period, latest readings - needed until first WebSocket message with power and consumption comes)
   */
  private getMeterData(): void {
    this.subscription.push(this.meterService.getMeterWithReadings(this.data.id, this.dateFrom, this.dateTo)
      .subscribe((value: Meter) => {
        this.meter = value;
      }, error1 => {
        this.messager.error('Nepodařilo se načíst data měřáku. Chyba: ' + error1.error);
      }));
  }

  /**
   * Loads data for historical power chart
   */
  private getReadingsForPowerChart(): void {
    this.subscription.push(this.readingService.getReadingsWithFilters(this.data.id, 'power', this.dateFrom, this.dateTo).subscribe(
      (value: Reading[]) => {
        value.map(item => {
          this.histPowerChartData[0].data.push(item.value / 1000);
          this.histPowerChartLabels.push(moment(item.timestamp).format('DD.MM.yyyy HH:mm:ss'));
        });
      }));
  }

  /**
   * Load consumption sum values for historical consumption chart
   */
  private getReadingsForConsumptionChart(): void {
    this.subscription.push(this.readingService.getReadingsWithFilters(this.data.id, 'consumption', this.dateFrom, this.dateTo).subscribe(
      (readings: Reading[]) => {
        readings.map((item: Reading) => {
          this.histConsumpChartData[0].data.push(item.value / 1000);
          this.histConsumpChartLabels.push(moment(item.timestamp).format('DD.MM.yyyy HH:mm:ss'));
        });
      }
    ));
  }

  /**
   * Subscribe to power and consumption readings from WebSocket
   */
  private subscribeToWsReadings(): void {
    // Subscribe to power readings from websocket
    this.subscription.push(this.wsService.subscribeToMeterReadings<ClientMeterReadingPower>(this.data.id, (value: ClientMeterReadingPower) => {
      if (value) {
        this.meter.power = value.VALUE;
        this.chartData[0].data.push((value.VALUE / 1000));
        this.chartLabels.push(moment(value.TIMESTAMP).format('HH:mm:ss'));
        // there can be at maximum AppConfig.LIVE_CHART_MAX_POINTS shown on the chart in order to be readable
        if (this.chartData[0].data.length >= AppConfig.LIVE_CHART_MAX_POINTS) {
          this.chartData[0].data.shift();
          this.chartLabels.shift();
        }
      } else {
        this.messager.warning('Chyba při čtení příkonu elektroměru!');
      }
    }, ClientMeterReadingPower));

    // Subscribe to power readings from websocket
    this.subscription.push(this.wsService.subscribeToMeterReadings<ClientMeterReadingConsumption>(this.data.id, (value: ClientMeterReadingConsumption) => {
      if (value) {
        this.meter.consumptionRt = value.VALUE;
      } else {
        this.messager.warning('Chyba při čtení spotřeby elektroměru!');
      }
    }, ClientMeterReadingConsumption));

    // Subscribe to online state readings from websocket
    this.subscription.push(this.wsService.subscribeToMeterReadings<ClientMeterOnline>(this.data.id, (value: ClientMeterOnline) => {
      if (value) {
        this.meter.online = value.ONLINE;
      } else {
        this.messager.warning('Chyba při čtení online/offline stavu elektroměru!');
      }
    }, ClientMeterOnline));
  }
}
