import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentPageComponent } from './components/current-page/current-page.component';
import { MessagerComponent } from './components/messager/messager.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {MaterialComponentsModule} from '../material-components/material-components.module';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import {ReactiveFormsModule} from '@angular/forms';
import { DeviceEditComponent } from './components/device-edit/device-edit.component';
import { DeviceDetailComponent } from './components/device-detail/device-detail.component';
import {GoogleMapsModule} from '@angular/google-maps';
import { DeviceUniTableComponent } from './components/device-uni-table/device-uni-table.component';
import { MeterUniTableComponent } from './components/meter-uni-table/meter-uni-table.component';
import { MeterEditComponent } from './components/meter-edit/meter-edit.component';
import { MeterDetailComponent } from './components/meter-detail/meter-detail.component';
import { GlobalFiltersComponent } from './components/global-filters/global-filters.component';
import {LoaderComponent} from './components/loader/loader.component';
import {ChartsModule} from 'ng2-charts';
import { UniLineChartComponent } from './components/uni-line-chart/uni-line-chart.component';
import { MetersMapComponent } from './components/meters-map/meters-map.component';
import { MapInfoWindowComponent } from './components/map-info-window/map-info-window.component';
import { HasRoleDirective } from './directives/has-role.directive';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { PositionSelectMapComponent } from './components/position-select-map/position-select-map.component';
import { GroupUniTreeComponent } from './components/group-uni-tree/group-uni-tree.component';
import { GroupEditComponent } from './components/group-edit/group-edit.component';
import { GroupDetailComponent } from './components/group-detail/group-detail.component';
import { MatTableExporterModule } from 'mat-table-exporter';



@NgModule({
  declarations: [CurrentPageComponent, MessagerComponent, ConfirmDialogComponent, UserEditComponent, DeviceEditComponent, DeviceDetailComponent, DeviceUniTableComponent, MeterUniTableComponent, MeterEditComponent, MeterDetailComponent, GlobalFiltersComponent, LoaderComponent, UniLineChartComponent, MetersMapComponent, MapInfoWindowComponent, HasRoleDirective, DatetimePickerComponent, PositionSelectMapComponent, GroupUniTreeComponent, GroupEditComponent, GroupDetailComponent],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    ChartsModule,
    MatTableExporterModule,
  ],
  exports: [
    CurrentPageComponent,
    DeviceUniTableComponent,
    MeterUniTableComponent,
    GlobalFiltersComponent,
    LoaderComponent,
    HasRoleDirective,
    GroupUniTreeComponent
  ]
})
export class SharedModule { }
