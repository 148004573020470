export const promiseTimeout = <T>(ms, promise): Promise<T> => {

  const timeout = new Promise<T>((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject('Timed out in ' + ms + 'ms.');
    }, ms);
  });

  return Promise.race([
    promise,
    timeout
  ]);
};
