import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {GroupService} from '../../../services/data/group.service';
import {Group} from '../../../models/http/group/group.model';
import {Subscription} from 'rxjs';
import {MessagerService} from '../../../services/messager.service';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss']
})
export class GroupDetailComponent implements OnInit {
  groups: Group[];
  subscriptions: Subscription[] = [];
  constructor(
    private dialog: MatDialog,
    private groupService: GroupService,
    private messager: MessagerService,
    @Inject(MAT_DIALOG_DATA) public data: Group,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.groupService.getAll().subscribe((groups: Group[]) => {
      if (groups && groups.length) {
        this.groups = groups;
      } else {
        this.groups = [];
        this.messager.warning('Nebyl načten seznam skupin.');
      }
    }, error1 => {
      this.messager.error('Nepodařilo se načíst seznam skupin.');
    }));
  }

  getGroupById(id: number): Group {
    return this.groups.find((group: Group) => group.id === id);
  }

}
