import {Component, Inject, OnInit} from '@angular/core';
import {MessagerService} from '../../../services/messager.service';
import {UserService} from '../../../services/data/user.service';
import {FormBuilder, FormControl, FormGroup, Validator, Validators} from '@angular/forms';
import {Logger, LoggingService} from '../../../services/logging.service';
import {User} from '../../../models/http/user.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ProgressService} from '../../../services/progress.service';
import {AppConfig} from '../../../app.config';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  private log: Logger;
  user: User;
  form: FormGroup;
  submitted = false;
  title: string;
  newUserForm: boolean;

  // for roles - chip list
  selectable = true;
  rolesList = AppConfig.USER_ROLES;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private messager: MessagerService,
    private userService: UserService,
    private fb: FormBuilder,
    private progress: ProgressService,
    private logging: LoggingService,
    private dialogRef: MatDialogRef<UserEditComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.log = logging.getLogger(this.constructor.name);
  }

  ngOnInit(): void {
    this.user = this.data;
    this.newUserForm = !(this.user.id);
    this.title = this.newUserForm ? 'Nový uživatel' : ('Editace uživatele ' + this.user.username);

    this.form = this.fb.group({
      id: [this.user.id, Validators.nullValidator],
      username: [this.user.username, Validators.required],
      firstname: [this.user.firstname, Validators.required],
      lastname: [this.user.lastname, Validators.required],
      password: this.newUserForm ? [this.user.password, Validators.required] : [this.user.password, Validators.nullValidator],
      roles: [this.user.roles, Validators.nullValidator],
    });
  }

  get formControls() { return this.form.controls; }

  onSubmit(): void {
    const progress = this.progress.start();
    if (!this.newUserForm) {
      // current user - modify
      const formValue: any = {};
      Object.assign(formValue, this.form.value);
      delete formValue.password;

      this.userService.modify(formValue)
        .subscribe(
          value => {
            this.messager.success('Uživatel uložen');
            this.dialogRef.close(true);
          },
          error1 => {
            this.messager.error('Uživatele se nepodařilo uložit. Chyba: ' + error1);
            this.progress.end(progress);
          },
          () => { this.progress.end(progress); }
        );
    } else {
      // new user
      // current user - modify
      const formValue: any = {};
      Object.assign(formValue, this.form.value);
      delete formValue.id;
      this.userService.create(formValue)
        .subscribe(
          value => {
            this.messager.success('Uživatel vytvořen');
            this.dialogRef.close(true);
          },
          error1 => {
            this.messager.error('Uživatele se nepodařilo vytvořit. Chyba: ' + error1.message);
            this.progress.end(progress);
          },
          () => { this.progress.end(progress); }
        );
    }
  }

}
