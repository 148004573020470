<div class="app-loading blur-overlay d-flex justify-content-center">
  <div class="row d-flex align-items-center flex-column">
    <img src="assets/images/eOdecet_logo_bily_text.png" alt="eOdečet.cz">
    <mat-card class="mat-card mat-focus-indicator login-card mat-elevation-z4 p-4 mt-5">
      <mat-card-header class="mb-3">
        <mat-card-title>
          <mat-icon>security</mat-icon>
          Přihlášení
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="d-flex flex-column">
        <form [formGroup]="loginForm" (ngSubmit)="login()" class="d-flex flex-column">
          <mat-form-field appearance="fill">
            <mat-label>Uživatelské jméno</mat-label>
            <input matInput type="text" formControlName="username" required>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Heslo</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
          </mat-form-field>
          <button mat-raised-button color="primary" #loginBtn="matButton" [disabled]="this.loggingIn">
            <mat-icon class="mr-2">check</mat-icon>Přihlásit
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
