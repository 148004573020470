import {AbstractTableDataSource} from './abstract-table-data-source';
import {MessagerService} from '../../services/messager.service';
import {Meter} from '../../models/http/meter/meter.model';
import {MeterService} from '../../services/data/meter.service';
import {WebsocketService} from '../../services/socket/websocket.service';
import {ClientMeterReadingPower} from '../../models/socket/client-meter-reading-power.model';
import {GlobalFiltersService} from '../../services/global-filters.service';
import {ClientMeterReadingConsumption} from '../../models/socket/client-meter-reading-consumption.model';
import {Subscription} from 'rxjs';
import {Filters} from '../../models/filters';
import {ClientMeterOnline} from '../../models/socket/client-meter-online.model';

export class MetersTableDataSource extends AbstractTableDataSource<Meter> {
  totalCount: number;
  private meterSubscriptions: Subscription[] = [];

  constructor(
    private meterService: MeterService,
    private ws: WebsocketService,
    private messager: MessagerService,
    private globalFilters: GlobalFiltersService,
  ) {
    super();
    this.meterService.getTotalCount();
    this.meterService.totalCount.subscribe(next => {
      this.totalCount = next;
    });
    this.subscribeToPowerReadings();
    this.subscribeToConsumptionReadings();
    this.subscribeToMeterOnlineStates();
  }

  getMetersPage(filters: Filters): void {
    this.meterService.getMetersWithReadingsWithFilters(filters)
      .subscribe((value: Meter[]) => {
          this.setData(value);
        }, error1 => {
          this.messager.error('Nepodařilo se načíst data pro tabulku měřáků. Chyba: ' + error1.error);
        }
      );
  }

  subscribeToPowerReadings(): void {
    this.meterSubscriptions.push(this.ws.subscribeToMetersReadings((value: ClientMeterReadingPower) => {
      if (this.apiSubject.value && this.apiSubject.value.length) {
        this.apiSubject.getValue().forEach(((meter, index) => {
          if (meter.id === value.METER_ID) {
            meter.power = value.VALUE;
            meter.wsPowerDataReceived = true;
          }
        }));
      }
    }, ClientMeterReadingPower));
  }

  subscribeToConsumptionReadings(): void {
    this.meterSubscriptions.push(this.ws.subscribeToMetersReadings((value: ClientMeterReadingConsumption) => {
      if (this.apiSubject.value && this.apiSubject.value.length) {
        this.apiSubject.getValue().forEach((meter, index) => {
          if (meter.id === value.METER_ID) {
            meter.consumptionRt = value.VALUE;
            meter.wsConsumpDataReceived = true;
          }
        });
      }
    }, ClientMeterReadingConsumption));
  }

  subscribeToMeterOnlineStates(): void {
    this.meterSubscriptions.push(this.ws.subscribeToMetersReadings((value: ClientMeterOnline) => {
      if (this.apiSubject.value && this.apiSubject.value.length) {
        this.apiSubject.getValue().forEach((meter, index) => {
          if (meter.id === value.METER_ID) {
            meter.online = value.ONLINE;
          }
        });
      }
    }, ClientMeterOnline));
  }

  unsubscribeFromReadings(): void {
    this.meterSubscriptions.forEach(value => value.unsubscribe());
  }
}
