<form [formGroup]="form" (ngSubmit)="onSubmit()" class="edit-form">
  <h2 mat-dialog-title>{{ this.title }}</h2>
  <div mat-dialog-content class="d-flex flex-column">
      <mat-form-field appearance="outline" class="d-flex">
        <mat-label>Uživatelské jméno</mat-label>
        <input matInput type="text" formControlName="username" required>
        <mat-error *ngIf="this.formControls['username'].invalid && this.formControls['username'].errors.required">
          Vyplňte prosím uživatelské jméno
        </mat-error>
      </mat-form-field>
    <mat-form-field appearance="outline" class="d-flex">
      <mat-label>Jméno</mat-label>
      <input matInput type="text" formControlName="firstname" required>
      <mat-error *ngIf="this.formControls['firstname'].invalid && this.formControls['firstname'].errors.required">
        Vyplňte prosím jméno
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="d-flex">
      <mat-label>Příjmení</mat-label>
      <input matInput type="text" formControlName="lastname" required>
      <mat-error *ngIf="this.formControls['lastname'].invalid && this.formControls['lastname'].errors.required">
        Vyplňte prosím příjmení
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="d-flex">
      <mat-label>Heslo</mat-label>
      <input matInput type="password" formControlName="password">
      <mat-error *ngIf="this.newUserForm && this.formControls['password'].invalid && this.formControls['password'].errors.required">
        Vyplňte prosím heslo
      </mat-error>
      <mat-hint *ngIf="!this.newUserForm">Heslo vyplňujte pouze pokud chcete uživateli nastavit nové.</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" class="d-flex mt-3">
      <mat-label>Role uživatele</mat-label>
      <mat-select formControlName="roles" multiple>
        <mat-option *ngFor="let role of rolesList" [value]="role">
          {{ role }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="this.formControls['roles'].invalid && this.formControls['roles'].errors.required">
        Vyberte prosím jednu z rolí
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="mt-2">
    <mat-error *ngIf="this.form.invalid && (this.form.dirty || this.form.touched)">
      <mat-icon class="mr-2" matTooltip="Formulář obsahuje chyby">warning</mat-icon>
    </mat-error>
    <button mat-raised-button type="submit" [disabled]="submitted || !form.valid" color="primary">
      <mat-icon class="mr-2">save</mat-icon>Uložit
    </button>
    <button mat-raised-button mat-dialog-close>Zpět</button>
  </div>
</form>
