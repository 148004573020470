import { Injectable } from '@angular/core';
import {BaseHttpService} from '../base-http.service';
import {Reading} from '../../models/http/reading/reading.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {AppConfig} from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class ReadingService extends BaseHttpService<Reading>{

  constructor(
    private httpC: HttpClient
  ) {
    super(httpC, Reading);
  }

  /**
   * Get readings for meter of specified id and for particular date range
   * @param meterId number The Id of the meter to load the readings of
   * @param type string power | consumption
   * @param dateFrom Moment starting point in time
   * @param dateTo Moment ending point in time
   * @param page number Page index
   * @param pageSize number Size of the page to load
   */
  public getReadingsWithFilters(meterId: number, type: string, dateFrom: moment.Moment, dateTo: moment.Moment, page?: number, pageSize?: number): Observable<Reading[]> {
    const endpoint = `${this.endpoint}/${type}/${meterId}`;
    const params = { params: new HttpParams()
        .set('from', dateFrom.format(AppConfig.DEFAULT_TIMESTAMP_FORMAT))
        .set('to', dateTo.format(AppConfig.DEFAULT_TIMESTAMP_FORMAT))
    };
    if (page && pageSize) {
      params.params = params.params.set('page', page.toString())
        .set('pageSize', pageSize.toString());
    }

    return this.httpC.get(endpoint, params)
      .pipe(
        map((res: any) => res.map((item: Reading) => (new Reading().deserialize(item))))
      );
  }

  /**
   * Get power readings for meter of last hour
   * @param meterId number the Id of the meter
   */
  public getPowerLastHour(meterId: number): Observable<Reading[]> {
    const endpoint = `${this.endpoint}/power/${meterId}`;

    return this.getReadingsWithFilters(meterId, 'power', moment().subtract(1, 'hour'), moment());
  }

  /**
   * Get consumption readings for meter of last hour
   * @param meterId number the Id of the meter
   */
  public getConsumptionLastHour(meterId: number): Observable<Reading[]> {
    const endpoint = `${this.endpoint}/power/${meterId}`;

    return this.getReadingsWithFilters(meterId, 'consumption', moment().subtract(1, 'hour'), moment());
  }
}
