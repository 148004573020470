import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/http/user.model';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {Moment} from 'moment';
import {AppStateManager} from './app-state-manager.service';
import {Router} from '@angular/router';
import {Auth} from '../models/http/auth';
import {AuthResponse} from '../models/http/auth-response';
import {BaseHttpService} from './base-http.service';
import jwtDecode from 'jwt-decode';
import {Login} from '../models/login.model';
import {AppConfig} from '../app.config';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {MessagerService} from './messager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseHttpService<Auth>{
  private currentUser: Subject<User> = new Subject<User>();
  private userLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // the url requested by unauthenticated user to redirect to after authentication
  requestedUrl: string;
  currLogin: Login;

  constructor(
    private httpC: HttpClient,
    private appState: AppStateManager,
    private router: Router,
    private messager: MessagerService,
  ) {
    super(httpC, Auth);
    this.loginAfterReload();
  }

  public checkClearCredentials(): void {
    if ((!this.isLoggedIn()) && localStorage.getItem(AppConfig.LOGIN_TOKEN)) {
      this.logout();
    }
  }

  /**
   * Authorizes the user against the server
   * @param username
   * @param password
   */
  public login(username: string, password: string): Promise<AuthResponse|boolean> {
    const auth = new Auth();
    auth.setUsername(username)
      .setPassword(password);

    return this.httpC.post<AuthResponse>(this.endpoint, auth)
      .pipe(
        catchError(this.handleError),
        tap((result) => { this.setSession(result); }),
        shareReplay()
      ).toPromise();
  }

  /**
   * Sets the local session after successful login
   * @param authResponse
   */
  private setSession(authResponse: AuthResponse): void {
    const tokenDecoded: any = jwtDecode(authResponse.token);

    // get timestamp when token/login expires
    const expiresAt = moment.unix(tokenDecoded.exp);
    // logout automatically when token expires
    this.handleTokenExpiration(expiresAt);

    // create login object to be stored in localStorage
    const login = new Login().setToken(authResponse.token)
      .setExpiration(expiresAt)
      .setUserId(tokenDecoded.sub)
      .setUsername(tokenDecoded.upn)
      .setUserGroups(tokenDecoded.groups);
    this.currLogin = login;
    localStorage.setItem(AppConfig.LOGIN_TOKEN, JSON.stringify(login));

    const user = new User();
    user.setUsername(tokenDecoded.upn)
      .setGroups(tokenDecoded.groups);
    this.currentUser.next(user);
    this.userLoggedIn.next(true);
  }

  /**
   * Does action when login token expires - logout
   * @param expiresAt Moment Time point in the future, when token expires
   */
  private handleTokenExpiration(expiresAt: Moment): void {
    // get time to logout in milliseconds
    const expiresIn = moment.duration(expiresAt.diff(moment())).asMilliseconds();
    setTimeout(() => {
      this.messager.warning('Vaše přihlášení vypršelo. Přihlaste se prosím znova.');
      this.logout();
      }, expiresIn);
  }

  /**
   * Removes the login token from the browser's local storage
   */
  logout(): void {
    if (localStorage.getItem(AppConfig.LOGIN_TOKEN)) {
      localStorage.removeItem(AppConfig.LOGIN_TOKEN);
    }
    this.userLoggedIn.next(false);
    this.currentUser.next(null);
    this.currLogin = null;
    this.router.navigate(['/login']);
  }

  /**
   * Returns true if user token valid
   */
  public isLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  public _isLoggedIn(): boolean {
    return this.userLoggedIn.value;
  }

  /**
   * Returns observable with current user
   */
  public getCurrentUser(): Observable<User> {
    return this.currentUser.asObservable();
  }

  /**
   * Returns current login stored in localStorage
   */
  public getLogin(): Login {
    let login = null;

    if (this.currLogin) {
      login = this.currLogin;
    } else if (AppConfig.LOGIN_TOKEN in localStorage) {
      login = new Login().deserialize(JSON.parse(localStorage.getItem(AppConfig.LOGIN_TOKEN)));
      this.currLogin = login;
    }

    return login;
  }

  public getLoggedUserId(): string {
    return this.getLogin() ? this.getLogin().userId : null;
  }

  public getToken(): string {
    return (this.isLoggedIn() && this.getLogin()) ? this.getLogin().token : null;
  }

  public getRoles(): string[] {
    return this.getLogin() ? this.getLogin().userGroups : null;
  }

  private loginAfterReload(): void {
    if ((AppConfig.LOGIN_TOKEN in localStorage) && (this.isLoggedIn())) {
      const user = new User();
      const login = this.getLogin();
      user.setUsername(login.username)
        .setGroups(login.userGroups);
      this.currentUser.next(user);
      this.userLoggedIn.next(true);
      // logout automatically when token expires
      this.handleTokenExpiration(moment(login.expiresAt));
    }
  }
}
