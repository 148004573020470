<mat-dialog-content>
  <div class="map">
    <google-map #metersMap
      [center]="mapCenter"
      [options]="mapOptions">
      <map-marker #mapMarker="mapMarker" *ngFor="let marker of this.mapMarkers; index as i"
                  [options]="marker"
                  (mapClick)="openInfoWindow(mapMarker, i)">
      </map-marker>
      <map-info-window (closeclick)="closeInfoWindow()">
        <app-map-info-window *ngIf="mapInfoWindowOpened" [meter]="this.meters[this.clickedMarkerIndex]"></app-map-info-window>
      </map-info-window>
    </google-map>
  </div>
</mat-dialog-content>
