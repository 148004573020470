import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ProgressService} from '../../../services/progress.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  visible: boolean;
  subscription: Subscription;

  constructor(private progressService: ProgressService) { }

  ngOnInit(): void {
    this.subscription = this.progressService.isLoading.subscribe(value => this.visible = value);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
