<div class="d-flex flex-row align-items-stretch justify-content-center py-3">
  <div class="d-flex align-items-center mr-4">
    <span class="mr-4">Softwarové řešení</span>
    <a href="https://www.webappheroes.cz/" target="_blank">
      <img src="../../../assets/images/web_app_heroes_logo.svg" alt="WebAppHeroes - automatizace procesů, webové aplikace, vývoj software">
    </a>
  </div>
  <div class="d-flex align-items-center">
    <span class="mr-4">Hardware</span>
    <a href="http://www.wernherd.cz/" target="_blank">
      <img src="../../../assets/images/wernherd-logo.png" alt="Wernherd technology - zakázkový hardware a bateriové systémy">
    </a>
  </div>
</div>
