import { Injectable } from '@angular/core';
import {WebsocketService} from './socket/websocket.service';
import {ClientMeterReadingConsumption} from '../models/socket/client-meter-reading-consumption.model';
import {Logger, LoggingService} from './logging.service';
import {ClientMeterReadingPower} from '../models/socket/client-meter-reading-power.model';

@Injectable({
  providedIn: 'root'
})
export class WsReadingsBufferService {
  private consumpReadings: Map<number, ClientMeterReadingConsumption>;
  private powerReadings: Map<number, ClientMeterReadingPower>;
  private log: Logger;

  constructor(
    private ws: WebsocketService,
    private logging: LoggingService,
  ) {
    this.consumpReadings = new Map<number, ClientMeterReadingConsumption>();
    this.powerReadings = new Map<number, ClientMeterReadingPower>();
    this.log = this.logging.getLogger(this.constructor.name);
    this.subscribeToConsumptionReadings();
    this.subscribeToPowerReadings();
  }

  private subscribeToConsumptionReadings(): void {
    this.ws.subscribeToMetersReadings((reading: ClientMeterReadingConsumption) => {
      if (reading.METER_ID && reading.VALUE) {
        this.consumpReadings.set(reading.METER_ID, reading);
      } else {
        this.log.warn('Consumption reading without meter id and value.');
      }}, ClientMeterReadingConsumption);
  }

  private subscribeToPowerReadings(): void {
    this.ws.subscribeToMetersReadings((reading: ClientMeterReadingPower) => {
      if (reading.METER_ID && reading.VALUE) {
        this.powerReadings.set(reading.METER_ID, reading);
      } else {
        this.log.warn('Power reading without meter id and value.');
      }}, ClientMeterReadingPower);
  }

  public getLastPowerReading(meterId: number): ClientMeterReadingPower {
    if (this.powerReadings.has(meterId)) {
      return this.powerReadings.get(meterId);
    } else {
      return null;
    }
  }

  public getLastConsumptionReading(meterId: number): ClientMeterReadingConsumption {
    if (this.consumpReadings.has(meterId)) {
      return this.consumpReadings.get(meterId);
    } else {
      return null;
    }
  }
}
