import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import * as moment from 'moment';
import {Reading} from '../../../models/http/reading/reading.model';

@Component({
  selector: 'app-uni-line-chart',
  templateUrl: './uni-line-chart.component.html',
  styleUrls: ['./uni-line-chart.component.scss']
})
/**
 * Universal line chart component for showing data values over time
 */
export class UniLineChartComponent implements OnInit {
  public chartOptions = {
    responsive: true,
  };
  public chartType: ChartType = 'line';
  public chartData: ChartDataSets[];
  public chartLabels: Label[];
  public color: Color[] = [{
    backgroundColor: 'rgba(33, 150, 243)'
  }];

  // Label of the data shown - e.g. "Power (kW)"
  @Input() dataLabel: string;
  // The data points to be shown on the chart
  @Input() data: Reading[];
  constructor() {
    this.chartData = [
      { data: [], label: this.dataLabel }
    ];
  }

  ngOnInit(): void {
    if (this.data && this.data.length) {
      this.chartData[0].data = this.data.map(val => val.value);
      this.chartLabels = this.data.map(val => moment(val.timestamp).format('dd.mm.yyyy HH:mm:ss'));
    }
  }

}
