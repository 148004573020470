import {Component, Inject, OnInit} from '@angular/core';
import {Logger, LoggingService} from '../../../services/logging.service';
import {Meter} from '../../../models/http/meter/meter.model';
import {AppConfig} from '../../../app.config';
import {User} from '../../../models/http/user.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MeterService} from '../../../services/data/meter.service';
import {MessagerService} from '../../../services/messager.service';
import {UserService} from '../../../services/data/user.service';
import {ProgressService} from '../../../services/progress.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Device} from '../../../models/http/device/device.model';
import {DeviceService} from '../../../services/data/device.service';
import {MeterAssignment} from '../../../models/http/meter/meter-assignment.model';

@Component({
  selector: 'app-meter-edit',
  templateUrl: './meter-edit.component.html',
  styleUrls: ['./meter-edit.component.scss']
})
export class MeterEditComponent implements OnInit {
  private log: Logger;
  meter: Meter;
  meterPermissions = AppConfig.METER_PERMISSIONS;
  users: User[];
  devices: Device[];
  form: FormGroup;
  permAssignForm: FormGroup;
  submitted = false;
  title: string;
  constructor(
    private deviceService: DeviceService,
    private meterService: MeterService,
    private userService: UserService,
    private messager: MessagerService,
    private fb: FormBuilder,
    private progress: ProgressService,
    private logging: LoggingService,
    private dialogRef: MatDialogRef<MeterEditComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
  ) {
    this.log = this.logging.getLogger(this.constructor.name);
  }

  ngOnInit(): void {
    this.meter = this.data;
    this.title = (this.meter.id) ? ('Editace měřiče ' + this.meter.title) : 'Nový měřič';
    this.loadUsers();
    this.loadDevices();

    this.form = this.fb.group({
      id: [this.meter.id, Validators.nullValidator],
      title: [this.meter.title, Validators.required],
      description: [this.meter.description, Validators.nullValidator],
      deviceId: [this.meter.deviceId, Validators.required],
      index: [this.meter.index, Validators.compose([Validators.min(0), Validators.max(9)])]
    });

    this.permAssignForm = this.fb.group({
      permissions: this.fb.array([
        this.fb.group({
          user: [null, Validators.required],
          permission: [null, Validators.required]
        })
      ])
    });

    if (this.meter.owners) {
      this.permissions.removeAt(0);
      Object.keys(this.meter.owners).forEach((key) => {
        this.permissions.push(this.fb.group({
          user: key, permission: this.meter.owners[key]
        }));
      });
    }
  }

  get formControls() { return this.form.controls; }
  get permissions() { return this.permAssignForm.get('permissions') as FormArray; }

  /**
   * Adds empty permission to permission assignment form
   */
  addPermision(): void {
    this.permissions.push(this.fb.group({
      user: [null, Validators.required],
      permission: [null, Validators.required]
    }));
  }

  removePermission(index: number): void {
    if (index >= 0 && index < this.permissions.length) {
      const removing = this.progress.start();
      const perm = this.preparePermission(index);
      this.meterService.removeDeviceAssignment(perm).subscribe(
        () => {
          this.messager.success('Oprávnění odebráno');
          this.permissions.removeAt(index);
        }, error1 => {
          this.messager.error('Oprávnění se nepodařilo odebrat. Zkuste to znova nebo kontaktujte administrátora.');
          this.progress.end(removing);
        }, () => {
          this.progress.end(removing);
        }
      );
    }
  }

  savePermission(index: number): void {
    if (index >= 0 && index < this.permissions.length) {
      const saving = this.progress.start();
      const perm = this.preparePermission(index);
      this.meterService.addMeterAssignment(perm).subscribe(
        (next) => {
          this.messager.success('Oprávnění přidáno');
        }, error1 => {
          this.messager.error('Oprávnění se nepodařilo přiřadit');
          this.progress.end(saving);
        }, () => {
          this.progress.end(saving);
        }
      );
    }
  }

  private preparePermission(index: number): MeterAssignment {
    const perm = new MeterAssignment();
    const assign = this.permissions.at(index).value;
    perm
      .setUser(assign.user)
      .setMeterId(this.meter.id)
      .setPermission(assign.permission);
    return perm;
  }

  onSubmit(): void {
    const progress = this.progress.start();
    if (this.meter.id) {
      console.log("Editace měřiče");
      console.log(this.meter.id);
      this.meterService.modify(this.form.value).subscribe(value => {
          this.messager.success('Elektroměr uložen');
        }, error1 => {
          this.messager.error('Elektroměr se nepodařilo uložit. Chyba: ' + error1.message);
          this.progress.end(progress);
        }, () => { this.progress.end(progress); }
      );
    } else {
      const met: Meter = Object.assign(new Meter(), this.form.value);
      delete met.id;
      console.log("Nový měřič");
      this.meterService.create(met)
        .subscribe(
          value => {
            this.messager.success('Elektroměr ' + value.title + ' vytvořen');
            this.dialogRef.close(true);
          }, error1 => {
            this.messager.error('Elektroměr se nepodařilo vytvořit. Chyba: ' + error1.message);
            this.progress.end(progress);
          }, () => { this.progress.end(progress); }
        );
    }
  }

  private loadUsers(): void {
    const loading = this.progress.start();
    this.userService.getAll().subscribe((value => {
      this.users = value;
    }), error1 => {
      this.messager.error('Nepodařilo se načíst dostupné uživatele pro výběr oprávnění.');
      this.progress.end(loading);
    }, () => {
      this.progress.end(loading);
    });
  }

  private loadDevices(): void {
    const loading = this.progress.start();
    this.deviceService.getAll().subscribe((value => {
      this.devices = value;
    }), error1 => {
      this.messager.error('Nepodařilo se načíst dostupné uzly pro výběř přiřazení elektroměru.');
      this.progress.end(loading);
    }, () => {
      this.progress.end(loading);
    });
  }

}
