<div mat-dialog-title>
  <h2>Detail uzlu {{ data.description }}</h2>
  <span class="title-note">Uzel sdružuje 1-10 elektroměrů</span>
</div>
<div mat-dialog-content class="pb-3">
  <google-map
    *ngIf="this.data.gpsLongitude"
    [options]="mapOptions"
    [center]="mapCenter"
  >
    <map-marker
      [label]="data.description"
      [position]="mapCenter"
    ></map-marker>
  </google-map>
  <p *ngIf="!this.data.gpsLongitude">Uzel nemá vyplněné GPS souřadnice - nelze zobrazit mapu.</p>
  <mat-divider class="my-3"></mat-divider>
  <mat-accordion>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <strong>Měřiče připojené do uzlu</strong>
      </mat-expansion-panel-header>
      <app-meter-uni-table
        [device]="this.data.id"
        [showMapButton]="false">
      </app-meter-uni-table>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div mat-dialog-actions class="mt-2">
  <button mat-raised-button mat-dialog-close>Zavřít</button>
</div>
