import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AppPageInterface, PageTitle} from '../../interfaces/app-page.interface';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MessagerService} from '../../services/messager.service';
import {ProgressService} from '../../services/progress.service';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AppPageInterface, OnInit, AfterViewInit {
  pageTitle: PageTitle = {
    icon: 'login',
    name: 'Přihlášení'
  };
  loginForm: FormGroup;
  loggingIn = false;
  hide = true;
  @ViewChild('loginBtn') loginBtn: MatButton;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private messager: MessagerService,
    private progress: ProgressService,
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.loginBtn.focus();
  }

  /**
   * Handler of login form submit button click
   */
  login(): void {
    const login = this.loginForm.value;

    if (login.username && login.password) {
      const loading = this.progress.start();
      this.loggingIn = true;
      this.auth.login(login.username, login.password)
        .then((result) => {
          if (result) {
            const requestedUrl = this.auth.requestedUrl ? this.auth.requestedUrl : '/dashboard';
            this.router.navigate([requestedUrl]);
          }
        })
        .catch((error) => {
          this.messager.error('Nepodařilo se přihlásit. Pravděpodobně jste zadali špatné uživatelské jméno nebo heslo.');
        })
        .then(() => {
          this.progress.end(loading);
          this.loggingIn = false;
        });
    } else {
      this.messager.warning('Nevyplnili jste uživatelské jméno nebo heslo!');
    }
  }

}
