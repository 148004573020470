<button mat-raised-button *ngIf="isAdmin" color="primary" (click)="onAddClick()" class="mr-2">
  <mat-icon class="mr-2">add_box</mat-icon>Přidat elektroměr
</button>
<button mat-raised-button *ngIf="showMapButton" color="primary" (click)="onShowMapClick()" class="mr-2">
  <mat-icon class="mr-2">location_on</mat-icon>Zobrazit mapu měřičů
</button>
<div class="table-responsive" *ngIf="dataSource">
  <mat-table matTableExporter #metersTable matSort [dataSource]="dataSource" (matSortChange)="onSortChange($event)" class="default-table"
             #exporter="matTableExporter"
              [hiddenColumns]="[1, 4, 5]">
    <!-- title column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Název
      </mat-header-cell>
      <mat-cell *matCellDef="let meter" data-label="Název">{{ meter.title }}</mat-cell>
    </ng-container>
    <!-- index column -->
    <ng-container matColumnDef="index" *ngIf="isAdmin">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Index v poli měřáků
      </mat-header-cell>
      <mat-cell *matCellDef="let meter" data-label="Index v poli měřáků">{{ meter.index }}</mat-cell>
    </ng-container>
    <!-- power column -->
    <ng-container matColumnDef="power" *ngIf="showReadings">
      <mat-header-cell *matHeaderCellDef>
        Příkon (kW)
      </mat-header-cell>
      <mat-cell *matCellDef="let meter" data-label="Aktuální příkon" class="text-power smaller">
        <span>{{ meter.getMeterPower().toFixed(2) }}</span>
      </mat-cell>
    </ng-container>
    <!-- latest consumption -->
    <ng-container matColumnDef="consumption" *ngIf="showReadings">
      <mat-header-cell *matHeaderCellDef>
        Spotřeba (kWh)
      </mat-header-cell>
      <mat-cell *matCellDef="let meter" data-label="Spotřeba" class="text-consumption smaller">
        <span>{{ meter.getMeterConsumption().toFixed(1) }}</span>
      </mat-cell>
    </ng-container>
    <!-- consumption in selected period column -->
    <ng-container matColumnDef="consumptionInPeriod" *ngIf="showReadings">
      <mat-header-cell *matHeaderCellDef>
        Spotřeba za vybrané období (kWh)
      </mat-header-cell>
      <mat-cell *matCellDef="let meter" data-label="Spotřeba za vybrané období" class="text-consumption smaller">{{ meter.getMeterConsumptionInPeriod()?.toFixed(2) }}</mat-cell>
    </ng-container>
    <!-- meter states column -->
    <ng-container matColumnDef="states">
      <mat-header-cell mat-sort-header *matHeaderCellDef>
        Stav měřidla
      </mat-header-cell>
      <mat-cell *matCellDef="let meter" data-label="Stav měřidla">
        <div class="state-indicator" [class.online]="meter.online" [class.offline]="!meter.online">
          <mat-icon *ngIf="meter.online">check</mat-icon>
          <mat-icon *ngIf="!meter.online">error_outline</mat-icon>
          {{ meter.online ? 'Online' : 'Offline' }}
        </div>
      </mat-cell>
    </ng-container>
    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        Akce
      </mat-header-cell>
      <mat-cell *matCellDef="let meter" data-label="Akce">
        <button mat-icon-button color="primary" title="Zobrazit detaily" (click)="onDetailClick(meter)" matTooltip="Zobrazit detaily">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isAdmin" color="primary" title="Upravit měřák" (click)="onEditClick(meter)" matTooltip="Upravit měřák">
          <mat-icon>create</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isAdmin" color="primary" title="Smazat měřák" (click)="onDeleteClick(meter)" matTooltip="Smazat měřák" color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [length]="dataSource?.totalCount"
    [pageSize]="defaultPageSize"
    [pageSizeOptions]="pageSizes"
    (page)="onUpdatePageSize($event)">
  </mat-paginator>
  <div *ngIf="allowExport">
    <mat-form-field appearance="outline">
      <mat-label>Formát dat pro export</mat-label>
      <mat-select #exportFormat="matSelect">
        <mat-option *ngFor="let format of this.dataExportFormats" [value]="format">
          {{ format }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button class="va-middle ml-2" color="primary" (click)="exporter.exportTable(exportFormat.value, exportOptions)">
      <mat-icon class="mr-2" matTooltip="Exportovat data do Excelu">download</mat-icon>
      Exportovat
    </button>
  </div>
</div>
