import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DeviceService} from '../../../services/data/device.service';
import {MessagerService} from '../../../services/messager.service';
import {ProgressService} from '../../../services/progress.service';
import {Device} from '../../../models/http/device/device.model';
import {GlobalFiltersService} from '../../../services/global-filters.service';
import {Subscription} from 'rxjs';
import {GroupService} from '../../../services/data/group.service';
import {Group} from '../../../models/http/group/group.model';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss']
})
export class DeviceDetailComponent implements OnInit, OnDestroy {
  device: Device = new Device();
  group: Group = new Group();
  mapCenter: google.maps.LatLngLiteral;
  mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false
  };
  subscriptions: Subscription[] = [];
  constructor(
    private deviceService: DeviceService,
    private groupService: GroupService,
    private globalFilters: GlobalFiltersService,
    private messager: MessagerService,
    private dialog: MatDialogRef<DeviceDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Device,
  ) {
  }

  ngOnInit(): void {
    this.mapCenter = {
      lat: this.data.gpsLatitude,
      lng: this.data.gpsLongitude
    };
    if (this.data.group) {
      this.loadGroup();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subs: Subscription) => {
        subs.unsubscribe();
      });
    }
  }

  private loadGroup(): void {
    this.subscriptions.push(this.groupService.getSingle(this.data.group).subscribe((group: Group) => {
      this.group = group;
    }, error1 => {
      this.messager.error('Chyba při načítání detailu skupiny, do které uzel patří.');
    }));
  }
}
