export class MeterAssignment {
  meterId: number;
  userId: string;
  permission: string;

  setMeterId(id: number): this {
    this.meterId = id;
    return this;
  }

  setUser(userId: string): this {
    this.userId = userId;
    return this;
  }

  setPermission(perm: string): this {
    this.permission = perm;
    return this;
  }
}
