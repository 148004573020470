import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AuthGuard} from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  {
    path: 'zaznam-mereni',
    loadChildren: () => import('./modules/meters-records/meters-records.module').then(m => m.MetersRecordsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'funkce',
    loadChildren: () => import('./modules/app-functions/app-functions.module').then(m => m.AppFunctionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nastaveni',
    loadChildren: () => import('./modules/app-settings/app-settings.module').then(m => m.AppSettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
