import {SortDirection} from '@angular/material/sort';

export interface QueryFilter {
  name: string;
  value: string | number;
}

export const FILT_PAGE_SIZE = 'pageSize';
export const FILT_PAGE = 'page';
export const SORT_COL = 'sortColumn';
export const SORT_DIRECTION = 'sortDirection';

export const enum SORT_DIRS
{
  ASC = 1,
  DESC,
}

/**
 * Basic class to create queery filters for HTTP requests
 */
export class Filters {
  private filters: Map<string, any> = new Map<string, any>();

  public getFilterValue(name: string): string {
    let result = null;
    if (this.filters.has(name)) {
      result = this.filters.get(name);
    }
    return result;
  }

  public getFilters(): Map<string, string | boolean> {
    return this.filters;
  }

  public add(name: string, value: string | number): this {
    this.filters.set(name, value.toString());
    return this;
  }

  public setPageSize(value: string | number): this {
    this.filters.set('pageSize', value.toString());
    return this;
  }

  public setPage(value: string | number): this {
    this.filters.set('page', value.toString());
    return this;
  }

  public setSortCol(value: string): this {
    this.filters.set('sortColumn', value);
    return this;
  }

  public setSortDir(value: SortDirection): this {
    const dir = (value === 'desc') ? 'Descending' : 'Ascending';
    this.filters.set('sortDirection', dir);
    return this;
  }

  /**
   * Sets date filter - from
   * Valid for query: Device, Meter, Reading
   * @param value
   */
  public setDateFrom(value: string): this {
    this.filters.set('from', value);
    return this;
  }

  /**
   * Sets date filter - to.
   * Valid for query: Device, Meter, Reading
   * @param value
   */
  public setDateTo(value: string): this {
    this.filters.set('to', value);
    return this;
  }

  /**
   * Set Groups filter.
   * Valid for query: Meter
   * @param value Array of number - ids to be filtered. Pass null to clear the filter.
   */
  public setGroups(value: number[]): this {
    if (value) {
      // set the filter
      this.filters.set('groups', value);
    } else {
      // clear the filter
      this.filters.delete('groups');
    }
    return this;
  }

  /**
   * Get current Groups filter value
   */
  public getGroups(): number[] {
    return this.filters.get('groups');
  }

  /**
   * Set Device filter.
   * Valid for query: Meter
   * @param value Device id
   */
  public setDevice(value: number): this {
    this.filters.set('device', value.toString());
    return this;
  }

  /**
   * Set online filter
   * Valid for query: Meter
   * @param value True - meters online, false - meters offline
   */
  public setOnline(value: boolean): this {
    this.filters.set('online', value);
    return this;
  }

  public hasFilters(): boolean {
    return (this.filters.size !== 0);
  }
}
