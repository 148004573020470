import { Injectable } from '@angular/core';
import {Meter} from '../models/http/meter/meter.model';
import MarkerOptions = google.maps.MarkerOptions;
import LatLngBounds = google.maps.LatLngBounds;

@Injectable({
  providedIn: 'root'
})
/**
 * Several helpers for using Angular Google Maps in the application
 */
export class MapHelpersService {

  constructor() { }

  /**
   * Generates the set of marker options from the array of meters or devices
   * @param items
   */
  getMarkerOptions(items: Meter[]): MarkerOptions[] {
    const markers = [];
    if (items.length) {
      items.forEach((item) => {
        markers.push({
          meterId: item.id,
          position: {lat: item.gpsLatitude, lng: item.gpsLongitude},
          label: {
            text: (item.getMeterPower().toFixed(1).toString() + ' kW'),
            className: 'map-marker',
            color: '#fff',
          },
          draggable: false,
          icon: {
            url: '../assets/images/map-marker-electricity.svg',
            scaledSize: {
              width: 60,
              height: 34
            }
          },
        });
      });
    }
    return markers;
  }

  buildInfoWindowContent(meter: Meter): string {
    let htmlContent = 'Data o měřiči nenačtena.';
    if (meter && meter.title) {
      htmlContent = `<h3>${meter.title}</h3>`;
      htmlContent += `Příkon (kW): <span class="text-power">${(meter.getMeterPower()) ? (meter.getMeterPower()) : 'n/a'}</span><br>`;
      htmlContent += `Celková spotřeba (kWh): <span class="text-consumption">${(meter.getMeterConsumption()) ? meter.getMeterConsumption() : 'n/a'}</span><br>`;
    }
    return htmlContent;
  }

  /**
   * Calculates bounds object according to markers to be rendered on the map
   * @param markers
   */
  getMapBoundsByMarkers(markers: MarkerOptions[]): LatLngBounds {
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    markers.forEach((marker) => {
      bounds.extend(marker.position);
    });
    return bounds;
  }
}
