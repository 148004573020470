<button mat-raised-button *ngIf="isAdmin" color="primary" (click)="onAddClick()">
  <mat-icon class="mr-2">add_box</mat-icon>Přidat měřicí zařízení
</button>
<div class="table-responsive" *ngIf="dataSource">
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)" class="default-table">
    <!-- ID column -->
    <ng-container matColumnDef="id">
      <mat-header-cell mat-sort-header *matHeaderCellDef>
        ID
      </mat-header-cell>
      <mat-cell *matCellDef="let device" data-label="ID">{{ device.id }}</mat-cell>
    </ng-container>
    <!-- description column -->
    <ng-container matColumnDef="description">
      <mat-header-cell mat-sort-header *matHeaderCellDef>
        Popis
      </mat-header-cell>
      <mat-cell *matCellDef="let device" data-label="Popis">{{ device.description }}</mat-cell>
    </ng-container>
    <!-- mac column -->
    <ng-container matColumnDef="mac">
      <mat-header-cell mat-sort-header *matHeaderCellDef>
        MAC adresa
      </mat-header-cell>
      <mat-cell *matCellDef="let device" data-label="MAC adresa">{{ device.mac }}</mat-cell>
    </ng-container>
    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        Akce
      </mat-header-cell>
      <mat-cell *matCellDef="let device" data-label="Akce">
        <button mat-icon-button color="primary" title="Zobrazit detaily" (click)="onDetailClick(device)" matTooltip="Zobrazit detaily">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isAdmin" color="primary" title="Upravit zařízení" (click)="onEditClick(device)" matTooltip="Upravit zařízení">
          <mat-icon>create</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isAdmin" color="primary" title="Smazat zařízení" (click)="onDeleteClick(device)" matTooltip="Smazat zařízení" color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [length]="dataSource?.totalCount"
    [pageSize]="defaultPageSize"
    [pageSizeOptions]="pageSizes"
    (page)="onUpdatePageSize($event)">
  </mat-paginator>
</div>
