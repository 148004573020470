import {Component, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppStateManager} from './services/app-state-manager.service';
import {AuthService} from './services/auth.service';
import {User} from './models/http/user.model';
import {UserService} from './services/data/user.service';
import {MessagerService} from './services/messager.service';
import {ProgressService} from './services/progress.service';
import {WsReadingsBufferService} from './services/ws-readings-buffer.service';
import {MatDialog} from '@angular/material/dialog';
import {UserEditComponent} from './shared/components/user-edit/user-edit.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  currentUser = new User();

  constructor(
    public appState: AppStateManager,
    public auth: AuthService,
    private userService: UserService,
    private messager: MessagerService,
    private progress: ProgressService,
    private wsReadingsBuffer: WsReadingsBufferService,
    private dialog: MatDialog,
  ) {  }

  ngOnInit(): void {
    this.auth.checkClearCredentials();
    this.auth.isLoggedIn().subscribe(
      value => {
        if (value) {
          const loading = this.progress.start();
          this.userService.getSingle(this.auth.getLoggedUserId())
            .subscribe((next) => {
              this.currentUser = next;
            }, error1 => {
              this.messager.error('Nepodařilo se načíst data o přihlášeném uživateli!');
              this.progress.end(loading);
            }, () => this.progress.end(loading));
        }
      }
    );
  }

  /**
   * Router outlet activated event handler
   * Sets the current page title to AppStateManager
   * @param event
   */
  public onRouterOutletActivate(event: any): void {
    if (event && event.hasOwnProperty('pageTitle')) {
      this.appState.setCurrentPage(event.pageTitle);
    } else {
      console.log('Component does not have pageTitle property!');
    }
  }

  /**
   * Handles logout click
   */
  public onLogoutClick(): void {
    this.auth.logout();
  }

  /**
   * Opens dialog to edit logged-in user
   * @param user
   */
  public onUserEditClick(user: User): void {
    this.dialog.open(UserEditComponent, {
      hasBackdrop: true,
      panelClass: ['edit-dialog', 'medium-wide'],
      data: user
    }).afterClosed().subscribe(result => {
      if (result) {
        // reload current user
        this.userService.getSingle(this.currentUser.id)
          .subscribe((value: User) => {
            // if ids match, replace current user by the reloaded one
            if (value.id === this.currentUser.id) {
              this.currentUser = value;
            }
        });
      }
    });
  }
}
