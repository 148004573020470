<button mat-raised-button color="primary" *ngIf="isAdmin" (click)="onAddClick()" class="mr-2">
  <mat-icon class="mr-2">add_box</mat-icon>Přidat skupinu
</button>
<div matSort class="row mt-4 tree-sort-header-row" (matSortChange)="sortData($event)">
  <div mat-sort-header="id" class="col col-3 pl-4 py-3">
    Id
  </div>
  <div mat-sort-header="name" class="col col-5 py-3">
    Název
  </div>
  <div class="col col-4 py-3">
    Akce
  </div>
</div>
<mat-tree [dataSource]="dataSource" [treeControl]="nestedTreeControl" class="basic-tree">
  <mat-tree-node *matTreeNodeDef="let group" matTreeNodeToggle>
    <li class="mat-tree-node row">
      <div class="col col-3">
        <button mat-icon-button disabled></button>
        {{ group.id }}
      </div>
      <div class="col col-5">
        {{ group.name }}
      </div>
      <div class="col col-4 actions">
        <button mat-icon-button color="primary" title="Zobrazit detaily" (click)="onDetailClick(group)" matTooltip="Zobrazit detaily">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isAdmin" color="primary" title="Upravit měřák" (click)="onEditClick(group)" matTooltip="Upravit skupinu">
          <mat-icon>create</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isAdmin" color="primary" title="Smazat měřák" (click)="onDeleteClick(group)" matTooltip="Smazat skupinu" color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </li>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let group; when: hasNestedChild">
    <li>
      <div class="mat-tree-node row">
        <div class="col col-3">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Rozbalit ' + group.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ nestedTreeControl.isExpanded(group) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          {{ group.id }}
        </div>
        <div class="col col-5">
          {{ group.name }}
        </div>
        <div class="col col-4 actions">
          <button mat-icon-button color="primary" title="Zobrazit detaily" (click)="onDetailClick(group)" matTooltip="Zobrazit detaily">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-icon-button *ngIf="isAdmin" color="primary" title="Upravit měřák" (click)="onEditClick(group)" matTooltip="Upravit skupinu">
            <mat-icon>create</mat-icon>
          </button>
          <button mat-icon-button *ngIf="isAdmin" color="primary" title="Smazat měřák" (click)="onDeleteClick(group)" matTooltip="Smazat skupinu" color="warn">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <ul [class.example-tree-invisible]="!nestedTreeControl.isExpanded(group)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
<mat-paginator [length]="dataSource.totalCount"
               [pageSize]="defaultPageSize"
               [pageSizeOptions]="pageSizes"
               (page)="onUpdatePageSize($event)">
</mat-paginator>
