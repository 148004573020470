import {Group} from '../../models/http/group/group.model';
import {BehaviorSubject} from 'rxjs';
import {AbstractTreeDataSource} from './abstract-tree.data-source';
import {GroupService} from '../../services/data/group.service';
import {MessagerService} from '../../services/messager.service';
import {Filters} from '../../models/filters';
import {Logger, LoggingService} from '../../services/logging.service';

export class GroupsTreeDataSource extends AbstractTreeDataSource<Group> {
  totalCount: number;
  private log: Logger;

  constructor(
    private groupService: GroupService,
    private messager: MessagerService,
    private logging: LoggingService
  ) {
    super();
    this.log = this.logging.getLogger(this.constructor.name);
    this.groupService.getTotalCount();
    this.groupService.totalCount.subscribe(next => {
      this.totalCount = next;
    });
  }

  getGroupsPage(filters: Filters): void {
    this.groupService.getWithFilters(filters)
      .subscribe((value: Group[]) => {
        this.data = value;
      }, error1 => {
        this.log.error('Error while loading data for tree of groups: ' + error1);
        this.messager.error('Nepodařilo se načíst data pro strom skupin.');
      });
  }
}
