import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Logger, LoggingService} from '../../../services/logging.service';
import {Device} from '../../../models/http/device/device.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessagerService} from '../../../services/messager.service';
import {DeviceService} from '../../../services/data/device.service';
import {UserService} from '../../../services/data/user.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../models/http/user.model';
import {PositionSelectMapComponent} from '../position-select-map/position-select-map.component';
import {Subscription} from 'rxjs';
import {GroupService} from '../../../services/data/group.service';
import {Group} from '../../../models/http/group/group.model';

@Component({
  selector: 'app-device-edit',
  templateUrl: './device-edit.component.html',
  styleUrls: ['./device-edit.component.scss']
})
export class DeviceEditComponent implements OnInit, OnDestroy {
  private log: Logger;
  device: Device;
  users: User[];
  groups: Group[];
  form: FormGroup;
  submitted = false;
  title: string;
  subscriptions: Subscription[] = [];
  constructor(
    private messager: MessagerService,
    private deviceService: DeviceService,
    private groupService: GroupService,
    private userService: UserService,
    private fb: FormBuilder,
    private logging: LoggingService,
    private dialogRef: MatDialogRef<DeviceEditComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data,
  ) {
    this.log = this.logging.getLogger(this.constructor.name);
}

  ngOnInit(): void {
    this.device = this.data;
    this.subscriptions.push(this.userService.getAll().subscribe((value => {
      this.users = value;
    }), error1 => {
      this.messager.error('Nepodařilo se načíst dostupné uživatele pro výběr oprávnění.');
      this.log.error(error1);
    }));
    this.subscriptions.push(this.groupService.getAll().subscribe((value => {
      this.groups = value;
    }), error1 => {
      this.messager.error('Nepodařilo se načíst seznam skupin.');
      this.log.error(error1);
    }));

    this.title = (this.device.id) ? ('Editace uzlu ' + this.device.id) : 'Nový uzel';

    this.form = this.fb.group({
      id: [this.device.id, Validators.nullValidator],
      mac: [this.device.mac, Validators.required],
      description: [this.device.description, Validators.required],
      gpsLatitude: [this.device.gpsLatitude, Validators.required],
      gpsLongitude: [this.device.gpsLongitude, Validators.required],
      group: [this.device.group, Validators.nullValidator],
      specification: [this.device.specification, Validators.nullValidator]
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());
    }
  }

  get formControls() { return this.form.controls; }

  onSubmit(): void {
    if (this.device.id) {
        this.deviceService.modify(this.form.value).subscribe(value => {
          this.messager.success('Uzel uložen');
          this.dialogRef.close(true);
        }, error1 => {
          this.messager.error('Uzel se nepodařilo uložit. Chyba: ' + error1.message);
        });
    } else {
      const dev: Device = Object.assign(new Device(), this.form.value);
      delete dev.id;
      this.deviceService.create(dev)
        .subscribe(
          value => {
            this.messager.success('Uzel ' + value.description + ' vytvořen');
            this.dialogRef.close(true);
          }, error1 => {
            this.messager.error('Uzel se nepodařilo vytvořit. Chyba: ' + error1.message);
          });
    }
  }

  selectPositionFromMap(): void {
    this.subscriptions.push(this.dialog.open(PositionSelectMapComponent, {
      data: this.device,
      hasBackdrop: true,
      panelClass: ['edit-dialog', 'wide', 'dialog-2nd-level']
    }).afterClosed()
      .subscribe((result) => {
      if (result && result.device) {
        this.form.get('gpsLatitude').setValue(result.device.gpsLatitude);
        this.form.get('gpsLongitude').setValue(result.device.gpsLongitude);
      }
    }));
  }

}
