import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import {GlobalFiltersService} from '../../../services/global-filters.service';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {state, style, transition, animate, trigger} from '@angular/animations';
import {Group} from '../../../models/http/group/group.model';
import {GroupService} from '../../../services/data/group.service';
import {MessagerService} from '../../../services/messager.service';
import {Logger, LoggingService} from '../../../services/logging.service';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-global-filters',
  templateUrl: './global-filters.component.html',
  styleUrls: ['./global-filters.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({
        right: '-0.5rem',
      })),
      state('hidden', style({
        right: '-17.5rem',
      })),
      transition('show => hidden', [
        animate('0.35s')
      ]),
      transition('hidden => show', [
        animate('0.35s')
      ])
    ])
  ],
})
export class GlobalFiltersComponent implements OnInit {
  private log: Logger;
  dateFrom = new FormControl(moment());
  dateTo = new FormControl(moment());
  groups: Group[];
  isOpened = true;

  constructor(
    private globalFilters: GlobalFiltersService,
    private groupService: GroupService,
    private messager: MessagerService,
    private logging: LoggingService,
  ) {
    this.log = this.logging.getLogger(this.constructor.name);
  }

  ngOnInit(): void {
    this.globalFilters.dateFrom$.subscribe((value) => {
      this.dateFrom.setValue(value);
    });
    this.globalFilters.dateTo$.subscribe((value) => {
      this.dateTo.setValue(value);
    });
    this.groupService.getAll().subscribe((value: Group[]) => {
      this.groups = value;
    }, error1 => {
      this.messager.error('Nenačteny skupiny pro globální filtr.');
    });
  }

  changeDateFrom(event: MatDatepickerInputEvent<moment.Moment>): void {
    this.globalFilters.setDateFrom(event.value);
  }

  changeDateTo(event: MatDatepickerInputEvent<moment.Moment>): void {
    this.globalFilters.setDateTo(event.value);
  }

  onGroupSelection(event: MatSelectChange): void {
    this.globalFilters.setGroups(event.value);
  }

  /**
   * Hides the filters card
   */
  toggle(): void {
    this.isOpened = !this.isOpened;
  }

}
