<h3>{{ this.meter.title }}</h3>
<table>
  <tr>
    <td class="pb-3">Příkon</td><td class="pl-3 text-power">{{ this.meter.getMeterPower()?.toFixed(2) }} kW</td>
  </tr>
  <tr>
    <td class="py-3">Spotřeba celkem</td><td class="pl-3 text-consumption">{{ this.meter.getMeterConsumption()?.toFixed(3) }} kWh</td>
  </tr>
  <tr>
    <td class="py-3">Spotřeba od {{ dateFrom.format('DD.MM.YYYY hh:mm') }} do {{ dateTo.format('DD.MM.YYYY hh:mm') }}</td><td class="pl-3 text-consumption">{{ meter.getMeterConsumptionInPeriod()?.toFixed(3) }} kWh</td>
  </tr>
</table>
