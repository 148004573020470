import {HttpModel} from './http.model';

export class User extends HttpModel {
  id: string;
  firstname: string;
  lastname: string;
  username: string;
  password: string;
  roles: string[];

  setUsername(username: string): User {
    this.username = username;
    return this;
  }

  setGroups(groups: string[]): User {
    this.roles = groups;
    return this;
  }

  toString(): string {
    return this.username;
  }
}
