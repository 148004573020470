import {HttpModel} from '../http.model';

export class Reading extends HttpModel {
  timestamp: string;
  type: string;
  value: number;

  setTimestamp(value: string): this {
    this.timestamp = value;
    return this;
  }

  setType(value: string): this {
    this.type = value;
    return this;
  }

  setValue(value: number): this {
    this.value = value;
    return this;
  }
}
