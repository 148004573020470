import {HttpModel} from './http/http.model';
import {Moment} from 'moment';

export class Login extends HttpModel {
  token: string;
  expiresAt: Moment;
  userId: string;
  username: string;
  userGroups: string[];

  setToken(value: string): this {
    this.token = value;
    return this;
  }

  setExpiration(value: Moment): this {
    this.expiresAt = value;
    return this;
  }

  setUserId(value: string): this {
    this.userId = value;
    return this;
  }

  setUsername(value: string): this {
    this.username = value;
    return this;
  }

  setUserGroups(value: string[]): this {
    this.userGroups = value;
    return this;
  }
}
