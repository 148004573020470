import { Injectable } from '@angular/core';
import {Logger, LoggingService} from '../logging.service';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {SocketModel} from '../../models/socket/socket.model';
import {WebSocketSubject} from 'rxjs/webSocket';
import {environment} from '../../../environments/environment';
import {promiseTimeout} from '../../shared/util/promise-timeout';
import {UnauthorizedError} from '../../exceptions/unauthorized-error';
import {AuthService} from '../auth.service';
import {ClientMeterReadingPower} from '../../models/socket/client-meter-reading-power.model';
import {ClientMeterReadingConsumption} from '../../models/socket/client-meter-reading-consumption.model';
import {ClientMeterOnline} from '../../models/socket/client-meter-online.model';

export const WEBSOCKET_TIMEOUT_LIMIT = 30000;
export const WEBSOCKET_SUCCESS = 'Accepted';
export const WEBSOCKET_FAILURE = 'Rejected';
export const WEBSOCKET_UNAUTHORIZED = 'unauthorized';

type WebsocketMappingFn = (response: any) => SocketModel;

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private log: Logger;
  private subscriptions: Subscription;
  private Connected = false;
  private ConnectionStatus: Subject<boolean> = new BehaviorSubject<boolean>(null);

  private readonly mappings: Array<WebsocketMappingFn>;

  private wsConnection: WebSocketSubject<any>;
  private apiMessages: BehaviorSubject<SocketModel>;

  constructor(
    private logging: LoggingService,
    private auth: AuthService,
  ) {
    this.log = logging.getLogger('WebsocketService');

    this.mappings = [];
    this.register((responseData: any): SocketModel => {
      switch (responseData['MSG_ID']) {
        case 'CLIENT_METER_READING_POWER':
          return new ClientMeterReadingPower().deserialize(responseData);
        case 'CLIENT_METER_READING_CONSUMPTION':
          return new ClientMeterReadingConsumption().deserialize(responseData);
        case 'CLIENT_METER_ONLINE':
          return new ClientMeterOnline().deserialize(responseData);
      }
    });
  }

  /**
   * Validates WS response data - must contain kind identifier
   * @param data Response data to validate
   */
  public static validateResponse(data): any {
    if (!data.hasOwnProperty('MSG_ID')) {
      throw new Error('Not a valid WS API response (property "MSG_ID" is missing")');
    }
    if (data.kind === 'Welcome') {
      return null;
    }

    return data;
  }

  private connect(): void {
    this.wsConnection = new WebSocketSubject(`${environment.backendWsUrl}?token=${this.auth.getToken()}`);
    this.apiMessages = new BehaviorSubject<SocketModel>(null);
    this.Connected = true;

    this.wsConnection
      .subscribe((message) => {
          try {
            const responseData = WebsocketService.validateResponse(message);
            if (responseData) {
              this.applyMappings(responseData);
            }
          } catch (e) {
            this.log.warn('Could not run WS mapping function: ' + e.message);
          }
        },
        (err) => {
          this.log.error(err);
          this.Connected = false;
          this.ConnectionStatus.next(false);
        },
        () => {
        this.ConnectionStatus.next(true);
      }
     );

    /**
     * Disconnect the websocket in case of logout
     */
    this.auth.isLoggedIn().subscribe((value => {
      if (!value) {
        this.disconnect();
      }
    }));
  }

  public register(mappingFunction: (response: any) => SocketModel): void {
    this.mappings.push(mappingFunction);
  }

  /**
   * Sends a message to API through WS
   * @param message SocketModel
   * @returns string The id of the message sent
   */
  public send(message: SocketModel): string {
    if (!this.Connected) {
      this.connect();
    }

    message.requestId = '_' + Math.random().toString(36).substr(2, 9);
    this.wsConnection.next(message);

    return message.requestId;
  }

  public subscribeToMetersReadings<T>(observer: (value: SocketModel) => void, constructor: { new (): T }): Subscription {
    if (!this.Connected) {
      this.connect();
    }

    const s = this.apiMessages.subscribe(value => {
      if (value instanceof  constructor) {
        observer(value);
      }
    });
    return s;
  }

  public subscribeToMeterReadings<T>(meterId: number, observer: (value: SocketModel) => void, constructor: { new (): T}): Subscription {
    if (!this.Connected) {
      this.connect();
    }

    const s = this.apiMessages.subscribe(value => {
      if (value && value instanceof constructor && value['METER_ID'] === meterId) {
        observer(value);
      }
    });
    return s;
  }

  public sendAndSubscribe<T>(request: SocketModel, observer: (value: SocketModel) => void, constructor: { new (): T }): void {
    const requestId = this.send(request);

    const s = this.apiMessages.subscribe(value => {
      if (value && value instanceof constructor && value.requestId === requestId) {
        observer(value);
        setTimeout(() => s.unsubscribe(), 100);
      }
    });
  }

  public subscribePeriod<T>(observer: (value: SocketModel) => void, constructor: { new (): T }): Subscription {
    return this.apiMessages.subscribe(value => {
      if (value && value instanceof constructor) {
        observer(value);
      }
    });
  }

  public createRequestPromise<T extends SocketModel>(model: SocketModel, constructor: { new (): T }): Promise<T> {
    const promise = new Promise<T>(((resolve, reject) => {
      this.sendAndSubscribe<T>(model, (value: any) => {
        if (value.result === WEBSOCKET_UNAUTHORIZED) {
          reject(new UnauthorizedError('Unauthorized request'));
        } else if (value.result === WEBSOCKET_FAILURE) {
          reject(value.error.message);
        } else if (value.result === WEBSOCKET_SUCCESS) {
          resolve(value);
        }
      }, constructor);
    }));

    return promiseTimeout<T>(WEBSOCKET_TIMEOUT_LIMIT, promise);
  }

  private applyMappings(responseData: any): void {
    for (const func of this.mappings) {
      const funcResult = func(responseData);
      if (funcResult != null) {
        this.apiMessages.next(funcResult);
      }
    }
  }

  public disconnect(): void {
    this.wsConnection.complete();
    this.apiMessages.complete();
    this.Connected = false;
  }
}
