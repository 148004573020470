import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessagerService} from '../../../services/messager.service';
import {Device} from '../../../models/http/device/device.model';
import {AppConfig} from '../../../app.config';
import {$e} from 'codelyzer/angular/styles/chars';

@Component({
  selector: 'app-position-select-map',
  templateUrl: './position-select-map.component.html',
  styleUrls: ['./position-select-map.component.scss']
})
export class PositionSelectMapComponent implements OnInit {
  public dialogTitle: string;
  protected mapCenter: google.maps.LatLngLiteral;
  protected markerPos: google.maps.LatLngLiteral;
  protected newSelectedPos: google.maps.LatLngLiteral;
  mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    gestureHandling: 'auto'
  };

  constructor(
    private dialogRef: MatDialogRef<PositionSelectMapComponent>,
    private messager: MessagerService,
    @Inject(MAT_DIALOG_DATA) public data: Device
  ) { }

  ngOnInit(): void {
    if (this.data.id) {
      // edit position of existing meter
      this.mapCenter = this.getDeviceGpsLiteral();
      this.markerPos = this.getDeviceGpsLiteral();
      this.mapOptions.zoom = 15;
      this.dialogTitle = `Vybrat polohu uzlu ${this.data.description}`;
    } else {
      // set position of new meter
      this.mapOptions.zoom = 8;
      this.dialogTitle = 'Vyberte polohu nového uzlu';
      try {
        this.setCurrentLocation();
      } catch (err) {
        this.messager.warning(`Nepovedlo se získat vaši polohu. ${err}`);
        this.mapCenter = AppConfig.CZ_CENTER_COORDS;
        this.markerPos = AppConfig.CZ_CENTER_COORDS;
      }
    }
  }

  /**
   * Tries to set the location of the user from the navigator
   * @throws Error when geolocation is not available
   */
  setCurrentLocation(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.data.gpsLatitude = position.coords.latitude;
        this.data.gpsLongitude = position.coords.longitude;
        this.mapOptions.zoom = 12;
        this.mapCenter = this.getDeviceGpsLiteral();
        this.markerPos = this.getDeviceGpsLiteral();
      });
    } else {
      throw new Error('Geolokace v prohlížeči není dostupná.');
    }
  }

  /**
   * Close the dialog and pass new coords to opening dialog
   */
  closeAndSaveClick(): void {
    this.data.gpsLatitude = this.markerPos.lat;
    this.data.gpsLongitude = this.markerPos.lng;
    this.messager.success('Vybrána nová GPS poloha uzlu. Změny se projeví až po uložení uzlu.');
    this.dialogRef.close({device: this.data});
  }

  /**
   * Close without saving - reset the marker position to device current position
   */
  closeClick(): void {
    this.markerPos = {
      lat: this.data.gpsLatitude,
      lng: this.data.gpsLongitude
    };
    this.dialogRef.close();
  }

  /**
   * Returns device coords as LatLngLiteral
   */
  getDeviceGpsLiteral(): google.maps.LatLngLiteral {
    return {
      lat: this.data.gpsLatitude,
      lng: this.data.gpsLongitude
    };
  }

  /**
   * User clicked on map to set the position
   * @param $event
   */
  onMapClick($event: google.maps.MouseEvent): void {
    this.markerPos = {
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng()
    };
  }

  /**
   * Sets position after user dragged the map marker
   */
  markerDragEnd($event: google.maps.MouseEvent): void {
    this.markerPos.lat = $event.latLng.lat();
    this.markerPos.lng = $event.latLng.lng();
  }
}
