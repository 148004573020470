import {AbstractTableDataSource} from './abstract-table-data-source';
import {DeviceService} from '../../services/data/device.service';
import {MessagerService} from '../../services/messager.service';
import {Device} from '../../models/http/device/device.model';
import {Filters} from '../../models/filters';

export class DevicesTableDataSource extends AbstractTableDataSource<Device> {
  totalCount: number;

  constructor(
    private deviceService: DeviceService,
    private messager: MessagerService,
  ) {
    super();
    this.deviceService.getTotalCount();
    this.deviceService.totalCount.subscribe(next => {
      this.totalCount = next;
    });
  }

  getDevicesPage(filters: Filters): void {
    this.deviceService.getWithFilters(filters)
      .subscribe((value: Device[]) => {
        this.setData(value);
      }, error1 => {
        this.messager.error('Nepodařilo se načíst data pro tabulku uzlů. Chyba: ' + error1);
      });
  }
}
