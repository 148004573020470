<h2 mat-dialog-title>
  {{ this.dialogTitle }}
</h2>
<mat-dialog-content>
  <p><mat-icon class="mr-2">info</mat-icon>Klikněte do mapy pro výběr nové polohy uzlu.</p>
  <div class="map">
    <google-map
                *ngIf="this.data && this.data.gpsLongitude"
                [options]="mapOptions"
                [center]="mapCenter"
                (mapClick)="onMapClick($event)">
      <map-marker
        [label]="data.description"
        [position]="markerPos"
        draggable="true"
      ></map-marker>
    </google-map>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="mt-4">
  <button mat-button color="primary" type="submit" (click)="closeAndSaveClick()">
    <mat-icon class="mr-2">save</mat-icon>Uložit vybranou pozici
  </button>
  <button mat-button (click)="closeClick()">Zavřít</button>
</div>

