import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {formatDate} from '@angular/common';
import {environment} from '../../environments/environment.prod';

export enum LogLevel {
  INFO,
  DEBUG,
  WARN,
  ERROR
}

export class Logger {
  constructor(
    private name: string,
    private http: HttpClient,
  ){}

  public log(level: LogLevel, message: string): void {
    const line = this.formatLine(level, message);
    if (environment.logging.console) {
      console.log(line);
    }
    if (environment.logging.file) {
      const body = new FormData();
      body.set('data', line);
      this.http.post('/log.php', body).subscribe();
    }
  }

  public info(message: string): void {
    this.log(LogLevel.INFO, message);
  }

  public debug(message: string): void {
    this.log(LogLevel.DEBUG, message);
  }

  public warn(message: string): void {
    this.log(LogLevel.WARN, message);
  }

  public error(message: string): void {
    this.log(LogLevel.ERROR, message);
  }

  private formatLine(level: LogLevel, message: string): string {
    return formatDate(new Date(), 'mmm d, yyyy HH:MM:ss', 'cs-cz') + ' [' + LogLevel[level] + '] [' + this.name + '] ' + message;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private http: HttpClient) { }

  public getLogger(name: string): Logger {
    return new Logger(name, this.http);
  }
}
