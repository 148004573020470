import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Meter} from '../../../models/http/meter/meter.model';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ClientMeterReadingPower} from '../../../models/socket/client-meter-reading-power.model';
import {ClientMeterReadingConsumption} from '../../../models/socket/client-meter-reading-consumption.model';
import {WebsocketService} from '../../../services/socket/websocket.service';
import * as moment from 'moment';
import {GlobalFiltersService} from '../../../services/global-filters.service';

@Component({
  selector: 'app-map-info-window',
  templateUrl: './map-info-window.component.html',
  styleUrls: ['./map-info-window.component.scss']
})
export class MapInfoWindowComponent implements OnInit, OnDestroy, OnChanges {
  subscription: Subscription[] = [];
  alreadyInitialized = false;

  // Date from/to for data acquisition
  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  @Input() meter: Meter;
  constructor(
    private wsService: WebsocketService,
    private globalFilters: GlobalFiltersService,
  ) { }

  ngOnInit(): void {
    this.subscribeToGlobalFilters();
    this.subscribeToWs();
    this.alreadyInitialized = true;
  }

  /**
   * Called when meter input changes - hence need to re-subscribe to power and consumption WS messages
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.alreadyInitialized) {
      // Re-subscribe only if component's input changes without destroying it
      // unsubscribe first
      if (this.subscription) {
        this.subscription.forEach(subs => subs.unsubscribe());
      }

      // subscribe again for the new meter
      this.subscribeToGlobalFilters();
      this.subscribeToWs();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.forEach(subs => subs.unsubscribe());
    }
  }

  /**
   * Subscribes to power and consumption WS readings
   */
  private subscribeToWs(): void {
    // Subscribe to power readings from websocket
    this.subscription.push(this.wsService.subscribeToMeterReadings<ClientMeterReadingPower>(this.meter.id, (value: ClientMeterReadingPower) => {
      if (value) {
        this.meter.power = value.VALUE;
      }
    }, ClientMeterReadingPower));

    // Subscribe to consumption readings from websocket
    this.subscription.push(this.wsService.subscribeToMeterReadings<ClientMeterReadingConsumption>(this.meter.id, (value: ClientMeterReadingConsumption) => {
      if (value) {
        this.meter.consumptionRt = value.VALUE;
      }
    }, ClientMeterReadingConsumption));
  }

  /**
   * Subscribes to global filters values
   */
  private subscribeToGlobalFilters(): void {
    this.subscription.push(this.globalFilters.dateFrom$.subscribe((value) => this.dateFrom = value));
    this.subscription.push(this.globalFilters.dateTo$.subscribe((value) => this.dateTo = value));
  }
}
