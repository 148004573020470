import {HttpModel} from '../http.model';
import {Reading} from '../reading/reading.model';

export class Meter extends HttpModel {
  id: number;
  title: string;
  description: string;
  // id of the device to which meter is assigned
  deviceId: number;
  gpsLatitude: number;
  gpsLongitude: number;
  // index of the meter in the Device DATA array
  index: number;
  owners: Map<string, string>;
  online: boolean;
  // just a stupid indicator that ws data about power were received at least once
  wsPowerDataReceived: boolean;
  // just a stupid indicator that ws data about consumption were received at least once
  wsConsumpDataReceived: boolean;
  // online indicator from websocket
  wsOnline: boolean;
  // consumption in Wh - to be set real-time by incoming WS messages
  consumptionRt: number;
  // real-time power reading to be filled by incoming websocket messages
  power: number;
  // latest known readings to be shown at first, before websocket readings come
  readingPowerLatest: Reading;
  readingConsumptionLatest: Reading;
  // consumption per period calculated by back-end
  consumption: number;

  /**
   * Get latest power of the meter in kW
   */
  public getMeterPower(): number {
    let power = null;
    if (this.power !== undefined) {
      // consumption can be 0, that's why undefined in the condition
      power = this.power;
    } else if (this.readingPowerLatest !== undefined) {
      // consumption can be 0, that's why undefined in the condition
      power = this.readingPowerLatest.value;
    }

    return (power / 1000);
  }

  /**
   * Get latest total consumption value of the meter in kWh
   */
  public getMeterConsumption(): number {
    let consumption = null;
    if (this.consumptionRt !== undefined) {
      // consumption can be 0, that's why undefined in the condition
      consumption = this.consumptionRt;
    } else if (this.readingConsumptionLatest !== undefined) {
      // consumption can be 0, that's why undefined in the condition
      consumption = this.readingConsumptionLatest.value;
    }

    return (consumption / 1000);
  }

  /**
   * Returns the consumption in meter for period selected in GlobalFilters.
   * Value calculated on back-end.
   */
  public getMeterConsumptionInPeriod(): number {
    let consInPeriod = null;
    if (this.consumption !== undefined) {
      // consumption can be 0, that's why undefined in the condition
      consInPeriod = this.consumption / 1000;
    } else {
      consInPeriod = null;
    }

    return consInPeriod;
  }
}
