/**
 * Holder of http interceptors to be provided in AppModule
 */
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RequestFilterInterceptorService} from './request-filter-interceptor.service';
import {AuthInterceptorService} from './auth-interceptor.service';
import {LoaderInterceptor} from '../interceptor/loader.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RequestFilterInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
];
