import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {PageTitle} from '../interfaces/app-page.interface';

@Injectable({
  providedIn: 'root'
})
export class AppStateManager {
  private currentPage: Subject<PageTitle> = new Subject<PageTitle>();
  constructor() {
  }

  /**
   * Get current page title
   * @return PageTitle Name of the page.
   */
  getCurrentPage(): Observable<PageTitle> {
    return this.currentPage.asObservable();
  }

  /**
   * Set current page title
   * @param PageTitle value Name of the page.
   */
   public setCurrentPage(value: PageTitle): this {
    this.currentPage.next(value);
    return this;
  }
}
