import { Injectable } from '@angular/core';
import {BaseHttpService} from '../base-http.service';
import {Group} from '../../models/http/group/group.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends BaseHttpService<Group>{

  constructor(
    private httpC: HttpClient
  ) {
    super(httpC, Group);
  }
}
