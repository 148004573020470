import {DataSource} from '@angular/cdk/table';
import {CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';

export class AbstractTableDataSource<T> implements DataSource<T> {
  protected apiSubject = new BehaviorSubject<T[]>(null);
  protected loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public length = 0;

  connect(collectionViewer: CollectionViewer): Observable<any[] | ReadonlyArray<any>> {
    return this.apiSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.apiSubject.complete();
    this.loadingSubject.complete();
  }

  isEmpty(): boolean {
    return this.length === 0;
  }

  protected setData(items: T[]): void {
    if (items) {
      this.apiSubject.next(items);
      this.length = items.length;
    }
  }

  /**
   * Returns current set of data of type T
   */
  public getData(): T[] {
    return this.apiSubject.value;
  }
}
