import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ProgressService} from '../../services/progress.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
  visible = false;
  spinnerConf = {
    color: 'primary',
    strokeWidth: '6',
    diameter: '40'
  };
  subscription: Subscription;
  constructor(private progressService: ProgressService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscription = this.progressService.isLoading.subscribe(value => this.visible = value);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
