import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable, Subject} from 'rxjs';
import {MessagerComponent} from '../shared/components/messager/messager.component';

@Injectable({
  providedIn: 'root'
})
export class MessagerService {
  private subject = new Subject<any>();
  durationInSecs = 7;

  constructor(
    private snackBar: MatSnackBar) {
  }

  private showSnackBar(data): void {
    this.snackBar.openFromComponent(MessagerComponent, {
      duration: this.durationInSecs * 1000,
      panelClass: data.class,
      data: data
    });
  }

  success(message: string): void {
    this.showSnackBar({
      icon: 'check',
      class: 'success',
      message
    });
  }

  error(message: string): void {
    this.showSnackBar({
      icon: 'error',
      class: 'error',
      message
    });
  }

  warning(message: string): void {
    this.showSnackBar({
      icon: 'warning',
      class: 'warning',
      message
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
