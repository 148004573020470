import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Logger, LoggingService} from '../../../services/logging.service';
import {DevicesTableDataSource} from '../../data-sources/devices-table.data-source';
import {AppConfig} from '../../../app.config';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {DeviceService} from '../../../services/data/device.service';
import {MessagerService} from '../../../services/messager.service';
import {CommonsService} from '../../../services/commons.service';
import {MatDialog} from '@angular/material/dialog';
import {DeviceEditComponent} from '../device-edit/device-edit.component';
import {Device} from '../../../models/http/device/device.model';
import {DeviceDetailComponent} from '../device-detail/device-detail.component';
import {GlobalFiltersService} from '../../../services/global-filters.service';
import {Filters} from '../../../models/filters';

@Component({
  selector: 'app-device-uni-table',
  templateUrl: './device-uni-table.component.html',
  styleUrls: ['./device-uni-table.component.scss']
})
export class DeviceUniTableComponent implements OnInit, AfterViewInit {
  logger: Logger;
  dataSource: DevicesTableDataSource;

  displayedColumns = ['id', 'mac', 'description', 'actions'];
  defaultPageSize = AppConfig.DEFAULT_PAGE_SIZE;
  pageSizes = AppConfig.DEFAULT_PAGE_SIZES;

  private filters: Filters = new Filters();
  private tablePageSize: number;

  // Set to true if table should provide user interface for administration
  @Input() isAdmin = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private deviceService: DeviceService,
    private messager: MessagerService,
    private logging: LoggingService,
    private commons: CommonsService,
    private dialog: MatDialog,
  ) {
    this.logger = this.logging.getLogger(this.constructor.name);
  }

  ngOnInit(): void {
    this.dataSource = new DevicesTableDataSource(this.deviceService, this.messager);
  }

  ngAfterViewInit(): void {
    this.getDevicesPage();
  }

  private getDevicesPage(): void {
    this.filters.setPageSize(this.paginator.pageSize || this.defaultPageSize)
      .setPage(this.paginator.pageIndex)
      .setSortCol(this.sort.active)
      .setSortDir(this.sort.direction);
    this.dataSource.getDevicesPage(this.filters);
  }

  onAddClick(): void {
    this.dialog.open(DeviceEditComponent, {
      data: new Device(),
      hasBackdrop: true,
      panelClass: ['edit-dialog', 'medium-wide']
    }).afterClosed().subscribe(value => {
      if (value) {
        this.getDevicesPage();
      }
    });
  }

  onDetailClick(device: Device): void {
    this.dialog.open(DeviceDetailComponent, {
      data: device,
      hasBackdrop: true,
      panelClass: ['detail-dialog', 'wide']
    });
  }

  onEditClick(device: Device): void {
    this.dialog.open(DeviceEditComponent, {
      data: device,
      hasBackdrop: true,
      panelClass: ['edit-dialog', 'wide']
    }).afterClosed().subscribe(() => {
      this.getDevicesPage();
    });
  }

  /**
   * Delete device button click handler
   * @param device Device to be deleted
   */
  onDeleteClick(device: Device): void {
    this.commons.createConfirmDialog('Opravdu smazat uzel ' + device.description + '? Budou smazány také všechny měřiče do uzlu přiřazené!')
      .subscribe(value => {
        if (value) {
          this.deleteDevice(device);
        }
      });
  }

  private deleteDevice(device: Device): void {
    this.deviceService.delete(device.id).subscribe(value => {
        this.messager.success('Uzel i přiřazené měřiče ' + device.id + ' smazány');
        this.getDevicesPage();
      },
      error1 => {
        this.messager.error('Uzel ' + device.id + ' se nepodařilo smazat. Chyba: ' + error1);
      });
  }

  onUpdatePageSize(event: PageEvent): void {
    if (event.pageSize !== this.tablePageSize || event.pageIndex !== event.previousPageIndex) {
      this.tablePageSize = event.pageSize;
      this.getDevicesPage();
    }
  }

  onSortChange(event: Sort): void {
    this.getDevicesPage();
  }
}
