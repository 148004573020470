import { Injectable } from '@angular/core';
import {Device} from '../../models/http/device/device.model';
import {BaseHttpService} from '../base-http.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends BaseHttpService<Device>{
  constructor(private httpC: HttpClient) {
    super(httpC, Device);
  }

  /**
   * Gets device with readings for specified dates range
   * @param deviceId
   * @param dateFrom
   * @param dateTo
   */
  public getDeviceWithReadings(deviceId: number, dateFrom: moment.Moment, dateTo: moment.Moment): Observable<Device> {
    const url = `${this.endpoint}/${deviceId}?from=${dateFrom.toISOString().split('.')[0]}&to=${dateTo.toISOString().split('.')[0]}`;
    return this.httpC.get(url)
      .pipe(
        tap((next: Device) => new Device().deserialize(next))
      );
  }

  /**
   * Gets list of devices with given parameters from server
   * @param page
   * @param pageSize
   * @param groups
   */
  public getDevicesWithFilters(page?: number, pageSize?: number, groups?: number[]): Observable<Device[]> {
    const params = { params: new HttpParams() };
    if (page && pageSize) {
      params.params = params.params.set('page', page.toString()).set('pageSize', pageSize.toString());
    }
    return this.httpC.get(this.endpoint, params)
      .pipe(
        map((res: any) => res.map((entity: Device) => (new Device().deserialize(entity)))
      ));
  }
}
