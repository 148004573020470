<form [formGroup]="form" (ngSubmit)="onSubmit()" class="edit-form">
  <h2 mat-dialog-title>
    {{ title }}
  </h2>
  <div mat-dialog-content class="d-flex flex-column">
    <mat-form-field appearance="outline" class="d-flex">
      <mat-label>Název</mat-label>
      <input matInput type="text" formControlName="name" required>
      <mat-error *ngIf="this.formControls['name'].invalid && this.formControls['name'].errors.required">
        Vyplňte prosím název skupiny
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="d-flex">
      <mat-label>Nadřazená skupina</mat-label>
      <mat-select formControlName="parentId">
        <mat-option *ngFor="let group of groups" [value]="group.id">
          {{ group.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="this.formControls['parentId'].invalid">
        Vyberte platnou nadřazenou skupinu
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="mt-2">
    <button mat-button type="submit" [disabled]="submitted || !form.valid" color="primary">
      <mat-icon class="mr-2">save</mat-icon>Uložit
    </button>
    <mat-error *ngIf="this.form.invalid && (this.form.dirty || this.form.touched)">
      <mat-icon class="mr-2" matTooltip="Formulář obsahuje chyby">warning</mat-icon>
    </mat-error>
    <button mat-raised-button mat-dialog-close>Zpět</button>
  </div>
</form>
