<div mat-dialog-title class="d-flex flex-row justify-content-between">
  <div>
    <h2>Detail elektroměru {{ meter.title }}</h2>
    <span class="title-note">{{ meter.description }}</span>
  </div>
  <div class="state-indicator" [class.online]="meter.online" [class.offline]="!meter.online">
    <mat-icon *ngIf="meter.online">check</mat-icon>
    <mat-icon *ngIf="!meter.online">error_outline</mat-icon>
    {{ meter.online ? 'Online' : 'Offline' }}
  </div>
</div>
<div mat-dialog-content class="pb-3">
  <google-map
    *ngIf="this.data && this.data.gpsLongitude"
    [options]="mapOptions"
    [center]="mapCenter">
    <map-marker
      [label]="meter.title"
      [position]="mapCenter"></map-marker>
  </google-map>
  <p *ngIf="!this.data || !this.data.gpsLongitude">Nelze zobrazit mapu - měřák nemá vyplněné GPS souřadnice nebo nebyl načten správně.</p>
  <mat-divider class="my-3"></mat-divider>
  <table>
    <tr>
      <td class="pb-3">Příkon</td><td class="pl-3 text-power">{{ this.meter.getMeterPower()?.toFixed(2) }} kW</td>
    </tr>
    <tr>
      <td class="py-3">Spotřeba celkem</td><td class="pl-3 text-consumption">{{ this.meter.getMeterConsumption()?.toFixed(1) }} kWh</td>
    </tr>
    <tr>
      <td class="py-3">Spotřeba od {{ dateFrom.format('DD.MM.YYYY hh:mm') }} do {{ dateTo.format('DD.MM.YYYY hh:mm') }}</td><td class="pl-3 text-consumption">{{ meter.getMeterConsumptionInPeriod()?.toFixed(2) }} kWh</td>
    </tr>
  </table>
  <mat-divider class="my-3"></mat-divider>
  <mat-accordion>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <strong>Graf příkonu</strong>&nbsp;(data v reálném čase)
      </mat-expansion-panel-header>
      <p *ngIf="chartLabels.length" class="pt-2">Od: {{ this.chartLabels[0] }} do: {{ this.chartLabels[this.chartLabels.length - 1] }}</p>
      <div class="d-block">
        <canvas baseChart
                [datasets]="chartData"
                [labels]="chartLabels"
                [options]="powerChartOptions"
                [chartType]="chartType"></canvas>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <strong>Graf příkonu</strong>&nbsp;(historicky)
      </mat-expansion-panel-header>
      <p *ngIf="chartLabels.length" class="pt-2">Od: {{ this.histPowerChartLabels[0] }} do: {{ this.histPowerChartLabels[this.histPowerChartLabels.length - 1] }}</p>
      <div class="d-block">
        <canvas baseChart
                [datasets]="histPowerChartData"
                [labels]="histPowerChartLabels"
                [options]="powerChartOptions"
                [chartType]="chartType"></canvas>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <strong>Graf celkové spotřeby</strong>&nbsp;(historicky)
      </mat-expansion-panel-header>
      <p *ngIf="chartLabels.length" class="pt-2">Od: {{ this.histConsumpChartLabels[0] }} do: {{ this.histConsumpChartLabels[this.histConsumpChartLabels.length - 1] }}</p>
      <div class="d-block">
        <canvas baseChart
                [datasets]="histConsumpChartData"
                [labels]="histConsumpChartLabels"
                [options]="consumpChartOptions"
                [chartType]="chartType"></canvas>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <strong>Detaily měřiče</strong>
      </mat-expansion-panel-header>
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Položka</th>
          <th scope="col">Hodnota</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>Id</td>
            <td>{{ this.meter.id }}</td>
          </tr>
          <tr>
            <td>Id uzlu</td>
            <td>{{ this.meter.deviceId }}</td>
          </tr>
        </tbody>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div mat-dialog-actions class="mt-2">
  <button mat-raised-button mat-dialog-close>Zavřít</button>
</div>
